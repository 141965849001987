// contexts\engines\eng-model-type-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import handleChangeEvent from "../../events/handleChangeEvent";
import { useEngineContext } from "./engine-context";

const ModelTypeContext = React.createContext();

export const useModelTypeContext = () => useContext(ModelTypeContext);

export const EngModelTypeContextProvider = ({ children }) => {
  const [selectedEngModelTypes, setselectedEngModelTypes] = useState(null);
  const engineContext = useEngineContext();
  const [isLoading, setIsLoading] = useState(true); //new
  const [error, setError] = useState(null); //new
  const [page] = useState(0); // new

  const [modelTypes, setModelTypes] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    // Fetch data using async/await with Axios
    const fetchUsingAsyncAwaitWithAxios = async () => {
      try {
        if (engineContext.selectedEngine) {
          const BASE_URL = `https://tombaugh.tech/api/${engineContext.selectedEngine}/models-types`;

          const { data } = await axios.get(`${BASE_URL}?page=${page}`, {
            signal,
          });
          setModelTypes(data); // Set the fetched data
        } else {
          setModelTypes([]);
          setError(null); // Clear any previous errors
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Fetch aborted"); // Log a message when the request is intentionally aborted
          return; // Exit the function to prevent further error handling
        }
        setError(error.message);
      } finally {
        setIsLoading(false);
      } // Turn off loading indicator
    };

    // Trigger fetching method on component mount
    fetchUsingAsyncAwaitWithAxios();

    return () => {
      abortController.abort();
      setIsLoading(true); // Reset loading state
    };
  }, [engineContext.selectedEngine, page]); // Run the effect only once on component mount

  const handleEngModelChange = (event) => {
    handleChangeEvent(event, setselectedEngModelTypes);
  };

  return (
    <ModelTypeContext.Provider
      value={{
        modelTypes,
        selectedEngModelTypes,
        handleChangeEvent,
        handleEngModelChange,
      }}
    >
      {/* Display errors if any */}
      {error && <div>{error}</div>}
      {isLoading ? (
        <div>
          {/* <button onClick={() => setPage(page + 1)}>Loading... ({page})</button> */}
          <h3>Loading...</h3>
        </div>
      ) : (
        children // Render children once data is fetched
      )}
    </ModelTypeContext.Provider>
  );
};
