//src\contexts\engines\engine-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useManufContext } from "../vehicles/manuf-context";

const EngineContext = React.createContext();

export const useEngineContext = () => useContext(EngineContext);

export const EngineContextProvider = ({ children }) => {
  const [engines, setEngines] = useState([]);
  const [selectedEngine, setSelectedEngine] = useState(null); //selectedModel
  const manufContext = useManufContext();

  useEffect(() => {
    if (manufContext.selectedManufacturer) {
      axios
        .get(
          `https://tombaugh.tech/api/manufacturers/${manufContext.selectedManufacturer}/engines`
        )
        .then((res) => {
          setEngines(res.data);
        });
    } else {
      setEngines([]);
    }
  }, [manufContext.selectedManufacturer]);

  const handleEngineChange = (event) => {
    setSelectedEngine(event.target.value);
  };

  return (
    <EngineContext.Provider
      value={{
        engines,
        setEngines,
        selectedEngine,
        handleEngineChange,
      }}
    >
      {children}
    </EngineContext.Provider>
  );
};
