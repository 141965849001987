import React from "react";
import { SegmentGroup, Segment, Placeholder, PlaceholderLine  } from "semantic-ui-react";
import { useLocation } from 'react-router-dom';
import { ProdGenListContextProvider } from "../contexts/categories_tree/prod-gen-list"
import { ProductsContextProvider } from "../contexts/products/products"
import { ProductExtraContextProvider, useProductExtraContext } from "../contexts/additional_info/products-extra-contexts"
import { BrandsOfProductsContextProvider }   from "../contexts/products/brandsOfProducts"
import SearchByBrand from "../components/products/SearchByBrand";
import CardBarcode from "../components/additional_info/BarcodeCard"
import ProductInfo from "../components/additional_info/ProductInfo"
import OeList4One  from "../components/additional_info/OeList4One"


const ComponentRenderer = () => {

  const { activeComponent } = useProductExtraContext();

  return (
          <>
          {activeComponent === "CardBarcode" && <CardBarcode />}
          {activeComponent === "ProductInfo" && <ProductInfo />}
          {activeComponent === "OeList4One" && <OeList4One />}
          </>
  );
};



const Products = () => {

  const location = useLocation();
  const { state } = location;
  const selectedVehicle = state?.selectedVehicle; // Optional chaining to handle undefined state
  
  return (
    <ProdGenListContextProvider>
      <ProductsContextProvider>
        <ProductExtraContextProvider>
          <BrandsOfProductsContextProvider>
              <SegmentGroup basic >
              <Segment basic align="center">
                <Placeholder  color="orange" >
                <PlaceholderLine length='full' >
                  {selectedVehicle.manufacturer} -  
                  {selectedVehicle.model} - 
                  {selectedVehicle.type}             
                </PlaceholderLine>
                </Placeholder>
              </Segment>
              <SegmentGroup horizontal basic>
              <Segment basic color='blue'>
              <SearchByBrand />
              </Segment>
              <Segment basic color='orange' >
              <ComponentRenderer />
              </Segment>
              </SegmentGroup>
              </SegmentGroup>
          </BrandsOfProductsContextProvider>
        </ProductExtraContextProvider>
      </ProductsContextProvider>
    </ProdGenListContextProvider>
  );

};



export default Products;