//  src\components\show_table\ManufModelTypes.js

import React from "react";

import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useTypeContext } from "../../contexts/vehicles/type-context";
import { Segment } from "semantic-ui-react";
import { useHistoryContext } from "../../contexts/history/history-context";

const ShowManufModelTypes = () => {
  const navigate = useNavigate(); //  new

  const typeContext = useTypeContext();
  const vehicleTypes = typeContext.vehicleTypes;
  const { addHistory } = useHistoryContext();

  const tableHeaderstyle = {
    rows: {
      style: {
        maxHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#ccc",
      },
    },
  };


  const handleRowClick = (row) => {
    console.log("Clicked Row Data:", row);
    // Confirm the value before updating selectedVehicle
    console.log("Vehicle type ID before update:", row.vehicle_type_id);
    addHistory(row);
    navigate("/categories", { 
      state: { 
        selectedVehicle: {
          vehicle_type_id: row.vehicle_type_id,
          manufacturer: row.manufacturer,
          model: row.model,
          type: row.type
        }
      },
    }); // New
  };


  const columns = [
    {
      name: "Τύπος",
      selector: (row) => row.type,
      sortable: true,
      width: "auto",
      fontSize: "11px",
    },
    {
      name: "Χρονιά",
      selector: (row) => row.descrYear,
      sortable: true,
      // width: "slide",
      width: "auto",
      fontSize: "11px",
    },
    {
      name: "Τύπος Καυσίμου",
      selector: (row) => row.fueltype,
      sortable: true,
      fontSize: "11px",
    },
    {
      name: "Κινητήρας/ες",
      selector: (row) => row.listengines,
      sortable: true,
      width: "250px",
      fontSize: "11px",
    },
    {
      name: "Αριθ.Βαλβίδων",
      selector: (row) => row.valves,
      sortable: true,
      fontSize: "11px",
    },
    {
      name: "KW",
      selector: (row) => row.kw,
      sortable: true,
      fontSize: "11px",
    },
    {
      name: "Ιπποδύναμη",
      selector: (row) => row.hp,
      sortable: true,
      fontSize: "11px",
    },
    {
      name: "Κυβικά",
      selector: (row) => (row.cc ? row.cc : row.ccmtech),
      sortable: true,
      fontSize: "11px",
    },
    {
      name: "Σωματότυπος",
      selector: (row) => row.bodytype,
      width: "slide",
      sortable: true,
      fontSize: "11px",
    },
  ];

  return (
    <React.Fragment>
        <Segment basic>
          <DataTable
          title="Τύποι αυτοκινήτων βάσει μοντέλου"
            customStyles={tableHeaderstyle}
            columns={columns}
            data={vehicleTypes}
            highlightOnHover
            pagination
            onRowClicked={handleRowClick}
          />
        </Segment>
    </React.Fragment>
  );
};

export default ShowManufModelTypes;
