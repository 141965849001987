//  src\components\sidebar\sidebarSearch.js

import React from "react";
import { Header, Grid, Segment, Divider } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useManufContext } from "../../contexts/vehicles/manuf-context";
import { useModelContext } from "../../contexts/vehicles/model-context";
import { useEngineContext } from "../../contexts/engines/engine-context";
import classes from "./History.module.css";

const SidebarSearch = () => {
  const navigate = useNavigate();
  const manufContext = useManufContext();
  const modelContext = useModelContext();
  const engineContext = useEngineContext();


  const handleManufacturerChange = (event) => {
    manufContext.handleManufacturerChange(event);
  };

  const handleModelChange = (event) => {
    modelContext.handleModelChange(event);
    navigate("/passenger", { state: { selectedComponent: "ShowManufModelTypes" } });
  };

  const handleEngineChange = (event) => {
    engineContext.handleEngineChange(event);
    navigate("/passenger", { state: { selectedComponent: "ShowEngineTypesOfModel" } });
  };


  return (
    <Segment>
      <Grid textAlign={"center"} columns={1}>
        <Grid.Column stretched={true}>
          <Header as="h4">Αναζήτηση Οχήματος</Header>
          <select
            className={classes.pointer}
            value={manufContext.selectedManufacturer}
            onChange={handleManufacturerChange}
          >
            <option value=""> Κατασκευαστής </option>
            {manufContext.manufacturers.map((manufacturer) => (
              <option key={manufacturer.manuf_id} value={manufacturer.manuf_id}>
                {manufacturer.mancode}
              </option>
            ))}
          </select>
          <select
            className={classes.pointer}
            value={modelContext.selectedModel}
            onChange={handleModelChange}
          >
            <option value=""> Μοντέλο </option>
            {modelContext.vehicleModels.map((vehicleModel) => (
              <option
                key={vehicleModel.model_series_id}
                value={vehicleModel.model_series_id}
              >
                {vehicleModel.descrByYear}
              </option>
            ))}
          </select>
          <Divider horizontal>Or</Divider>

          <select
            className={classes.pointer}
            value={engineContext.selectedEngine}
            onChange={handleEngineChange}
          >
            <option value=""> Κινητήρας </option>
            {engineContext.engines.map((engine) => (
              <option key={engine.engdescr} value={engine.engdescr}>
                {engine.engdescr}
              </option>
            ))}
          </select>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};
export default SidebarSearch;
