//src\contexts\products\subcategory-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useCatNodes2Context } from "./cat-node2";

const CatNodes3Context = React.createContext();

export const useCatNodes3Context = () => useContext(CatNodes3Context);

export const CatNodes3ContextProvider = ({ children }) => {
    const [catNodes3, setCatNodes3] = useState([]);
    const [selectedCatNodes3, setSelectedCatNodes3] = useState(null);
  const catNodes2Context = useCatNodes2Context();


  useEffect(() => {
    if (catNodes2Context.selectedCatNodes2) {
      axios
        .get(
          `https://tombaugh.tech/api/${catNodes2Context.selectedCatNodes2}/nodes3/`
        )
        .then((res) => {
          setCatNodes3(res.data);
        });
    } else {
        setCatNodes3([]);
    }
  }, [catNodes2Context.selectedCatNodes2]);



  const handleCatNodes3Change = (selectedValue) => {
    setSelectedCatNodes3(selectedValue);
  };

  return (
    <CatNodes3Context.Provider
      value={{
        catNodes3,
        setCatNodes3,
        selectedCatNodes3,
        handleCatNodes3Change,
      }}
    >
      {children}
    </CatNodes3Context.Provider>
  );
};