// src/components/show_table/CardBarcode.js

//ShowCard.js
import React from "react";
import DataTable from "react-data-table-component";
import { Segment } from "semantic-ui-react";
import { useProductExtraContext } from "../../contexts/additional_info/products-extra-contexts";
import { Oval } from 'react-loader-spinner';


const OeList4One = () => {
  const productExtraContext = useProductExtraContext();
  const { oes, oesLoading } = productExtraContext;

  const tableHeaderstyle = {
    rows: {
      style: {
        maxHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#ccc",
      },
    },
  };

  const columns = [
    {
      name: "ΟΕ",
      selector: (row) => row.oe,
      sortable: true,
      width: "auto",
      fontSize: "11px",
    },
    {
      name: "Κατασκευαστής",
      selector: (row) => row.vehicles,
      sortable: true,
      width: "slide",
      fontSize: "11px",
    },
  ];

  return (

    <React.Fragment>
      <Segment basic>
        {
          oesLoading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
            <Oval color="#00BFFF" height={60} width={60} />
            </div>
          ) : (
            <DataTable
              title="OE Λίστα"
              customStyles={tableHeaderstyle}
              columns={columns}
              data={oes}
              highlightOnHover
              pagination
            />
          )
        }
      </Segment>
    </React.Fragment>
  );
};



export default OeList4One;
