//src\contexts\products\subcategory-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useCategoryContext } from "./category-context";

const SubCategoryContext = React.createContext();

export const useSubCategoryContext = () => useContext(SubCategoryContext);

export const SubCategoryContextProvider = ({ children }) => {
  const [sub_categories, setSub_categories] = useState([]);
  const [selectedSub_category, setSelectedSub_category] = useState(null);
  const categoryContext = useCategoryContext();

  useEffect(() => {
    if (categoryContext.selectedCategory) {
      axios
        .get(`https://tombaugh.tech/api/${categoryContext.selectedCategory}/sub_categories/`)
        .then((res) => {
          const distinctSubCategories = [
            ...new Set(
              res.data.map((sub_category) => sub_category.standard_group)
            ),
          ];
          setSub_categories(distinctSubCategories);
        });
    } else {
      setSub_categories([]);
    }
  }, [categoryContext.selectedCategory]);

  const handleSubCategoriesChange = (event) => {
    setSelectedSub_category(event.target.value);
  };

  return (
    <SubCategoryContext.Provider
      value={{
        sub_categories,
        setSub_categories,
        selectedSub_category,
        handleSubCategoriesChange,
      }}
    >
      {children}
    </SubCategoryContext.Provider>
  );
};
