import React, { useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import "@reach/menu-button/styles.css";// import { useProductByVehicleContext } from "../../contexts/show_table/product-context";
import { useProductsContext } from "../../contexts/products/products";
import { useProductExtraContext } from "../../contexts/additional_info/products-extra-contexts";
import DataTable from "react-data-table-component";
import { Segment, 
          Grid, 
          Button } from "semantic-ui-react";
import differenceBy from "lodash/differenceBy";
import uniqBy from "lodash/uniqBy";
import { Oval } from 'react-loader-spinner';

const ShowProducts = () => {

  const productsContext = useProductsContext();
  const productExtraContext = useProductExtraContext();
  const products = productsContext.products;
  const filter = productsContext.filter;
  const search = productsContext.search;
  const SetSearch = productsContext.SetSearch;
  const handleTableRowClick = productExtraContext.handleTableRowClick;
  const handle2TableRowClick = productExtraContext.handle2TableRowClick;
  const handle3TableRowClick = productExtraContext.handle3TableRowClick;
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [data, setData] = React.useState(products);
  const [multiOes, setMultiOes] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading

  const tableHeaderstyle = {
    rows: {
      style: {
        maxHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#ccc",
      },
    },
  };

  
  const columns = [
    {
      name: "Κωδικός Προϊόντος",
      selector: (row) => row.product_id,
      sortable: true,
    },
    {
      name: "Μάρκα",
      selector: (row) => row.brands,
      sortable: true,
    },
    {
      button: true,
      cell: (row) => (
        <Menu>
          <MenuButton>
            {" "}
            <HiDotsVertical />{" "}
          </MenuButton>
          <MenuList>
          <MenuItem
              onClick={(event) =>
                handle3TableRowClick(event, row.product_id, row.brand_id) 
              }
            >
              Λίστα με OE
            </MenuItem>
          <MenuItem
              onClick={(event) => {
                handle2TableRowClick(event, row.product_id, row.brand_id);
              }}
            >
              Πληροφορίες
            </MenuItem>
            <MenuItem
              onClick={(event) =>
                handleTableRowClick(event, row.product_id, row.brand_id) 
              }
            >
              Φωτογραφία
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    },
  ];

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

   const contextActions = React.useMemo(() => {
    const handleOes = () => {

      if (
        window.confirm(
          `Θα πραγματοποιηθεί σύνθετη αναζήτηση ΟΕ για τους κωδικούς:\r ${selectedRows.map(
            (r) => r.product_id
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, "title"));
      }

      const productIds = selectedRows.map((row) =>
        row.product_id.replace("/", "")
      );
      const brandIds = selectedRows.map((row) => row.brand_id);

      setLoading(true);  // Start loading

      fetch(
        `https://tombaugh.tech/api/${productIds.join(",")}/${brandIds.join(
          ","
        )}/oes`
      )
        .then((res) => res.json())
        .then((data) => {
          const uniqueOes = uniqBy(data, "oe");  // Ensure uniqueness by 'oe'
          setMultiOes(uniqueOes);
          setLoading(false);  // Stop loading
        })
        .catch(() => {
          setLoading(false);  // Stop loading on error
        });
    };

    return (
      <Button
        basic
        onClick={handleOes}
        color="blue"
        content="ΣΥΝΘΕΤΗ ΑΝΑΖΗΤΗΣΗ"
        icon="filter"
        label={{
          as: "a",
          basic: true,
          color: "blue",
          pointing: "left",
          content: "ΟΕ",
        }}
      />
    );
  }, [data, selectedRows, toggleCleared]);

  const oes = [
    {
      name: "Κωδικός ΟΕ",
      selector: (row) => row.oe,
      sortable: true,
    },
    {
      name: "Κατασκευαστής",
      selector: (row) => row.vehicles,
      sortable: true,
    },
  ];

  return (
  
    <Segment basic>
      <Grid.Column width={8}>
      <DataTable
              title="Προϊόντα"
              customStyles={tableHeaderstyle}
              columns={columns}
              data={filter}
              pagination
              selectableRows
              fixedHeader
              contextActions={contextActions}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
              highlightOnHover
              subHeader
              subHeaderComponent={
                <input
                  noDataComponent="jhjkhjkhjkhjkh"
                  type="text"
                  className="form-control"
                  placeholder="Αναζήτηση Μάρκας ... "
                  value={search}
                  onChange={(e) => SetSearch(e.target.value)}
                />
              }
              subHeaderAlign="left"
            />
      </Grid.Column>


      <Segment basic>
        <Grid.Column width={4}>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
        <Oval color="#00BFFF" height={60} width={60} />
        </div>
        ) : (
        multiOes.length > 0 && (
          <DataTable
            title="Λίστα OE - Σύνθετη Αναζήτηση"
            customStyles={tableHeaderstyle}
            columns={oes}
            data={multiOes}
            pagination
          />
        )
        )}
        </Grid.Column>
        </Segment>
    </Segment>
  );
};

export default ShowProducts;
