//src\contexts\products\category-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

const CatNodes1Context = React.createContext();

export const useCatNodes1Context = () => useContext(CatNodes1Context);

export const CatNodes1ContextProvider = ({ children }) => {
  const [catNodes1, setCatNodes1] = useState([]);
  const [filteredCatNodes1, setFilteredCatNodes1] = useState([]);
  const [selectedCatNodes1, setSelectedCatNodes1] = useState(null);

  useEffect(() => {
    axios.get("https://tombaugh.tech/api/nodes/").then((res) => {
      setCatNodes1(res.data);
    });
  }, []);

  useEffect(() => {
    const idsToFilter = [100005, 100006, 100016, 100008, 100002, 100007, 100243, 100012, 100013, 100214, 100011, 100018];
    const filteredResults = catNodes1.filter(node => idsToFilter.includes(node.node_id1));
    setFilteredCatNodes1(filteredResults);
  }, [catNodes1]);


  const handleCatNodes1Change = (selectedValue) => {
    setSelectedCatNodes1(selectedValue);
  };

  return (
    <CatNodes1Context.Provider
      value={{
        catNodes1,
        filteredCatNodes1,
        selectedCatNodes1,
        handleCatNodes1Change,
      }}
    >
      {children}
    </CatNodes1Context.Provider>
  );
};