//src\events\Pagination.js

import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "./pagination.css";

export const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage <= 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];
  const isPageDisabled = (pageNumber) =>
    pageNumber === 1 ? currentPage === 1 : currentPage === lastPage;
  const handlePageChange = (pageNumber) => onPageChange(pageNumber);

  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <li
        className={classnames("pagination-item", {
          disabled: isPageDisabled(currentPage - 1),
        })}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber) =>
        pageNumber === DOTS ? (
          <li className="pagination-item dots">&#8230;</li>
        ) : (
          <li
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        )
      )}
      <li
        className={classnames("pagination-item", {
          disabled: isPageDisabled(currentPage + 1),
        })}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;

// //src\events\Pagination.js
//
// import React from "react";
// import classnames from "classnames";
// import { usePagination, DOTS } from "./usePagination";
// import "./pagination.css";
//
// const Pagination = (props) => {
//   const {
//     onPageChange,
//     totalCount,
//     siblingCount = 1,
//     currentPage,
//     pageSize,
//     className,
//   } = props;
//
//   const paginationRange = usePagination({
//     currentPage,
//     totalCount,
//     siblingCount,
//     pageSize,
//   });
//
//   if (currentPage <= 0 || paginationRange.length < 2) {
//     return null;
//   }
//
//   const onNextPage = () => {
//     onPageChange(currentPage + 1);
//   };
//
//   const onPrevPage = () => {
//     onPageChange(currentPage - 1);
//   };
//
//   let lastPage = paginationRange[paginationRange.length - 1];
//   return (
//     <ul
//       className={classnames("pagination-container", { [className]: className })}
//     >
//       <li
//         className={classnames("pagination-item", {
//           disabled: currentPage === 1,
//         })}
//         onClick={onPrevPage}
//       >
//         <div className="arrow left" />
//       </li>
//       {paginationRange.map((pageNumber) => {
//         if (pageNumber === DOTS) {
//           return <li className="pagination-item dots">&#8230;</li>;
//         }
//
//         return (
//           <li
//             className={classnames("pagination-item", {
//               selected: pageNumber === currentPage,
//             })}
//             onClick={() => onPageChange(pageNumber)}
//           >
//             {pageNumber}
//           </li>
//         );
//       })}
//       <li
//         className={classnames("pagination-item", {
//           disabled: currentPage === lastPage,
//         })}
//         onClick={onNextPage}
//       >
//         <div className="arrow right" />
//       </li>
//     </ul>
//   );
// };
//
//
// export default Pagination;

// import React from 'react';
// import classnames from 'classnames';                //https://github.com/mayankshubham/react-pagination.git
// import { usePagination, DOTS } from './usePagination';    //How to Build a Custom Pagination Component in React
// import './pagination.css';            //https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
//
// const Pagination = props => {
//   const {
//     onPageChange,
//     totalCount,
//     siblingCount = 1,
//     currentPage,
//     pageSize,
//     className
//   } = props;
//
//   const paginationRange = usePagination({
//     currentPage,
//     totalCount,
//     siblingCount,
//     pageSize
//   });
//
//   if (currentPage === 0 || paginationRange.length < 2) {
//     return null;
//   }
//
//   const onNext = () => {
//     onPageChange(currentPage + 1);
//   };
//
//   const onPrevious = () => {
//     onPageChange(currentPage - 1);
//   };
//
//   let lastPage = paginationRange[paginationRange.length - 1];
//   return (
//     <ul
//       className={classnames('pagination-container', { [className]: className })}
//     >
//       <li
//         className={classnames('pagination-item', {
//           disabled: currentPage === 1
//         })}
//         onClick={onPrevious}
//       >
//         <div className="arrow left" />
//       </li>
//       {paginationRange.map(pageNumber => {
//         if (pageNumber === DOTS) {
//           return <li className="pagination-item dots">&#8230;</li>;
//         }
//
//         return (
//           <li
//             className={classnames('pagination-item', {
//               selected: pageNumber === currentPage
//             })}
//             onClick={() => onPageChange(pageNumber)}
//           >
//             {pageNumber}
//           </li>
//         );
//       })}
//       <li
//         className={classnames('pagination-item', {
//           disabled: currentPage === lastPage
//         })}
//         onClick={onNext}
//       >
//         <div className="arrow right" />
//       </li>
//     </ul>
//   );
// };
//
// export default Pagination;
