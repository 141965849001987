import axios from "axios";
import { useState } from "react";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
// import tombaugh from "../assets/tombaugh.png";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submit = async (e) => {
    e.preventDefault();

    const user = {
      username: username,
      password: password,
    };

    const { data } = await axios.post(
      "https://tombaugh.tech/api/token/",
      user,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
      { withCredentials: true }
    );

    console.log(data);
    localStorage.clear();
    localStorage.setItem("access_token", data.access);
    localStorage.setItem("refresh_token", data.refresh);
    axios.defaults.headers.common["Authorization"] = `Bearer ${data["access"]}`;
    window.location.href = "/";
  };

  return (
    <Grid textAlign="center" style={{ height: "50vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h3" color="orange" textAlign="center">
          {/*<Image src={tombaugh} /> */}
          Συνδέσου στο λογαριασμό σου
        </Header>
        <Form size="large" onSubmit={submit}>
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Username"
              type="e-mail"
              onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button type="submit" color="orange" fluid size="large">
              ΣΥΝΔΕΣΗ
            </Button>
          </Segment>
        </Form>
        {/*<Message>*/}
        {/*  ΔΕΝ ΕΧΕΤΕ ΛΟΓΑΡΙΑΣΜΟ ? <a href='/'>ΕΓΓΡΑΦΗ</a>*/}
        {/*</Message>*/}
      </Grid.Column>
    </Grid>
  );
};

// //  src\pages\Login.js

// import React, { useEffect, useReducer } from "react";
// import axios from "axios";
// import { Button, Grid, Form, Segment } from "semantic-ui-react";

// function loginReducer(state, action) {
//   switch (action.type) {
//     case "field": {
//       return {
//         ...state,
//         [action.fieldName]: action.payload,
//       };
//     }
//     case "login": {
//       return {
//         ...state,
//         error: "",
//         isLoading: true,
//       };
//     }
//     case "success": {
//       console.log("Response Data: ", action.payload); // Add this log statement // new
//       localStorage.setItem("access_token", action.payload.access);
//       localStorage.setItem("refresh_token", action.payload.refresh);
//       axios.defaults.headers.common[
//         "Authorization"
//       ] = `Bearer ${action.payload.access}`;

//       return {
//         ...state,
//         isLoggedIn: true,
//         isLoading: false,
//       };
//     }
//     case "error": {
//       return {
//         ...state,
//         error: "Incorect usename or password!!!",
//         isLoggedIn: false,
//         isLoading: false,
//       };
//     }
//     case "logOut": {
//       localStorage.removeItem("access_token");
//       localStorage.removeItem("refresh_token");
//       delete axios.defaults.headers.common["Authorization"];

//       return {
//         ...state,
//         isLoggedIn: false,
//       };
//     }
//     default:
//       return state;
//   }
// }
// const initialState = {
//   username: "",
//   password: "",
//   isLoading: false,
//   error: "",
//   isLoggedIn: false,
// };

// export const Login = () => {
//   const [state, dispatch] = useReducer(loginReducer, initialState);
//   const { username, password, isLoading, error, isLoggedIn } = state;

//   useEffect(() => {
//     if (isLoggedIn) {
//       window.location.href = "/"; // Redirect if user is logged in
//     }
//   }, [isLoggedIn]);

//   const onSubmit = async (e) => {
//     e.preventDefault();
//     dispatch({ type: "login" });
//     try {
//       // Add your API call here
//       const response = await axios.post("https://tombaugh.tech/token/", {
//         username,
//         password,
//       });

//       // Example check for successful login
//       if (response.status === 200) {
//         dispatch({ type: "success", payload: response });
//         // Check if user is logged in and then trigger redirect
//         // if (isLoggedIn) {
//         //   dispatch({ type: "redirect" });
//         // }
//       } else {
//         dispatch({ type: "error" });
//       }

//       // For now, using a placeholder dispatch for success
//       dispatch({ type: "success" });
//     } catch (error) {
//       dispatch({ type: "error" });
//     }
//   };

//   return (
//     <Grid textAlign="center" style={{ height: "50vh" }} verticalAlign="middle">
//       <Grid.Column style={{ maxWidth: 450 }}>
//         {isLoggedIn ? (
//           <>
//             <h1>Welcome {username}!</h1>
//             <button onClick={() => dispatch({ type: "logOut" })}>
//               Log Out
//             </button>
//           </>
//         ) : (
//           <Form size="large" onSubmit={onSubmit}>
//             <Segment stacked>
//               {error && <p className="error">{error}</p>}
//               <p>Please Login!</p>
//               <Form.Input
//                 type="text"
//                 placeholder="Username"
//                 value={username}
//                 onChange={(e) =>
//                   dispatch({
//                     type: "field",
//                     fieldName: "username",
//                     payload: e.currentTarget.value,
//                   })
//                 }
//               />
//               <Form.Input
//                 type="password"
//                 placeholder="Password"
//                 autoComplete="new-password"
//                 value={password}
//                 onChange={(e) =>
//                   dispatch({
//                     type: "field",
//                     fieldName: "password",
//                     payload: e.currentTarget.value,
//                   })
//                 }
//               />
//               <Button
//                 className="submit"
//                 type="submit"
//                 color="orange"
//                 size="small"
//                 disabled={isLoading}
//               >
//                 {isLoading ? "Logging in..." : "ΣΥΝΔΕΣΗ"}
//               </Button>
//             </Segment>
//           </Form>
//         )}
//       </Grid.Column>
//     </Grid>
//   );
// };
