//  src\App.js

import {
  // createBrowserRouter,
  // RouterProvider,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { HomePage } from "./pages/Home";
import RootLayout from "./pages/Root";
import Layout from "./pages/Layout";
import Passenger from "./pages/Passenger";
import Products from "./pages/Products";
import CategoriesNodes from "./pages/CategoriesNodes";
import LightCommercialPage from "./pages/LightCommercial";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
// import { NavigationProvider } from "./contexts/history/navigation-history"; // Import NavigationProvider

function App() {
  return (
    // <NavigationProvider>
    <BrowserRouter>
    <RootLayout></RootLayout>
      <Routes>
      <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        {/* Wrap these Routes with RootLayout */}
        <Route
          path="/passenger"
          element={<Layout><Passenger /></Layout>}
        />
        <Route
          path="/categories"
          element={<Layout><CategoriesNodes /></Layout>}
        />
        <Route path="/products" 
        element={<Layout><Products /></Layout>} />
        <Route
          path="/light_commercial"
          element={<Layout><LightCommercialPage /></Layout>}
          />
      </Routes>
    </BrowserRouter>
    // </NavigationProvider>
  );
}

export default App;