// src/pages/CategoriesNodes.js

import React, { useState, useEffect } from "react";
import { SegmentGroup, Segment, Placeholder, PlaceholderLine, Radio } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import ShortCategories from "../components/categories_tree/ShortCategories";
import SearchCategoryTree from "../components/categories_tree/CategoriesNodes";
// import CategoryFavorites from "../components/categories_tree/CategoryFavorites";
import { CatNodes1ContextProvider } from "../contexts/categories_tree/cat-node1";
import { CatNodes2ContextProvider } from "../contexts/categories_tree/cat-node2";
import { CatNodes3ContextProvider } from "../contexts/categories_tree/cat-node3";
import { CatNodes4ContextProvider } from "../contexts/categories_tree/cat-node4";
import { FavCategoriesContextProvider } from "../contexts/categories_tree/favorite-categories";
// import { useNavigation } from '../contexts/history/navigation-history';

const CategoriesNodes = () => {
  
  const location = useLocation();
  const { state } = location;
  const selectedVehicle = state?.selectedVehicle; // Optional chaining to handle undefined state
  const [categoryClicked, setCategoryClicked] = useState(false);
  // A unique key to force re-mount
  const [key, setKey] = useState(0);
// State to manage the toggle
  const [showCategoryTree, setShowCategoryTree] = useState(true);
  // const { pushState } = useNavigation();

useEffect(() => {
    // Whenever location changes, increment the key
    setKey(prevKey => prevKey + 1);
    setCategoryClicked(false); // Reset category clicked state
    setShowCategoryTree(true); // Reset the visibility of SearchCategoryTree
}, [location]);
// useEffect(() => {
//   // Whenever location changes, increment the key
//   setKey(prevKey => prevKey + 1);
//   setCategoryClicked(false); // Reset category clicked state
//   setShowCategoryTree(true); // Reset the visibility of SearchCategoryTree
  
//   // Push the current state to the navigation context
//   pushState({ selectedVehicle, categoryClicked, showCategoryTree });
// }, [location, pushState, selectedVehicle]);


const handleToggleChange = (e, { checked }) => {
  setShowCategoryTree(checked);
  if (checked) {
    setCategoryClicked(false); // You may or may not want to reset this
  }
  
  // Push the updated state to the navigation context when the toggle changes
  // pushState({ selectedVehicle, categoryClicked: false, showCategoryTree: checked });
};


    return (
      <CatNodes1ContextProvider>
          <CatNodes2ContextProvider>
            <CatNodes3ContextProvider>
              <CatNodes4ContextProvider>
                <FavCategoriesContextProvider>
                <SegmentGroup basic key={key}>
                <Segment basic align="center">
                <Placeholder color="orange">
                  <PlaceholderLine length="full">
                    {selectedVehicle?.manufacturer} -
                    {selectedVehicle?.model} -
                    {selectedVehicle?.type}
                  </PlaceholderLine>
                </Placeholder>
               <Segment basic align="right">
                <Radio
                  toggle
                  label="Όλες οι κατηγορίες"
                  checked={showCategoryTree}
                  onChange={handleToggleChange}
                />
                </Segment>
                </Segment>
                {/* <Segment basic compact>   
                <CategoryFavorites />
                </Segment> */}
                <Segment basic compact>
                  <ShortCategories 
                    onCategoryClick={() => {
                      setCategoryClicked(true);
                      setShowCategoryTree(false);
                      // Push the updated state to the navigation context when a category is clicked
                      // pushState({ selectedVehicle, categoryClicked: true, showCategoryTree: false });
                    }}
                    showAccordion={categoryClicked}
                  />
                </Segment>
                <Segment basic compact>
                  {showCategoryTree && !categoryClicked && <SearchCategoryTree />}
                </Segment>
                </SegmentGroup>
                </FavCategoriesContextProvider>       
            </CatNodes4ContextProvider>
        </CatNodes3ContextProvider>
      </CatNodes2ContextProvider>
    </CatNodes1ContextProvider>
    );

};



export default CategoriesNodes;