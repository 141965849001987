/* eslint-disable jsx-a11y/anchor-is-valid */
// src/components/categories_tree/CategoryFavorites.js
import React from 'react';
import Slider from "react-slick";
import { useNavigate, useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { useCatNodes1Context } from "../../contexts/categories_tree/cat-node1";
import { useFavCategoriesContext } from "../../contexts/categories_tree/favorite-categories";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomArrow from '../CustomArrow'; 

// Import all images from the images directory

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const images = importAll(require.context('../../images', false, /\.(png|jpe?g|svg)$/));

const CategoryFavorites = ( {onCategoryClick}) => {
    const navigate = useNavigate();
      const location = useLocation();
      const selectedVehicle = (location.state && location.state.selectedVehicle) || "";
      const catNodes1Context = useCatNodes1Context();
      const categories = catNodes1Context.filteredCatNodes1;
      const { favCategories } = useFavCategoriesContext();

    const handleValueClick = (selectedValue) => {
        navigate("/products", { state: { selectedValue, selectedVehicle } });
      };
  
  
    const settings = {
        dots: false, // Disable dots
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        nextArrow: <CustomArrow />,  // Right arrow customization
        prevArrow: <CustomArrow />   // Left arrow customization
      };
    // Create a map for ordering purposes
    const nodeOrder = [100005, 100006, 100016, 100008, 100002, 100007, 100243, 100012, 100013, 100214, 100011, 100018];
    const nodeOrderMap = new Map(nodeOrder.map((id, index) => [id, index]));
  
    // Sort the categories based on the nodeOrder
    const sortedCategories = categories.sort((a, b) => {
      return (nodeOrderMap.get(a.node_id1) ?? Number.MAX_SAFE_INTEGER) - 
             (nodeOrderMap.get(b.node_id1) ?? Number.MAX_SAFE_INTEGER);
    });

  
      return (
        <Slider {...settings}>
          {sortedCategories.map((category, index) => (
            <div key={category.node_id1}>
              <Popup
                content={category.str_text1}
                trigger={
                  <div onClick={() => onCategoryClick(index, category.node_id1, 0)}>
                    <img
                      src={images[`${category.node_id1}.png`]}
                      alt={`Icon for ${category.node_id1}`}
                      style={{ width: '120px', height: '120px' }}
                    />
                  </div>
                }
              />
              <div>
                {/* Display favCategories if node_id1 matches */}
                {favCategories
                  .filter(fav => fav.node_id1 === category.node_id1)
                  .map(fav => (
                    <div key={fav.node_fav_id}>
                      <a 
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleValueClick(fav.node_fav_id);
                        }}
                        style={{ color: 'blue', textDecoration: 'underline' }}
                      >
                        {fav.str_text_fav}
                      </a>
                    </div>
                  ))}
             </div>
        </div>
      ))}
    </Slider>
  );
};

export default CategoryFavorites;
