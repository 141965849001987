//src\contexts\products\subcategory-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useCatNodes1Context } from "./cat-node1";

const CatNodes2Context = React.createContext();

export const useCatNodes2Context = () => useContext(CatNodes2Context);

export const CatNodes2ContextProvider = ({ children }) => {
    const [catNodes2, setCatNodes2] = useState([]);
    const [selectedCatNodes2, setSelectedCatNodes2] = useState(null);
    const catNodes1Context = useCatNodes1Context();

  useEffect(() => {
    if (catNodes1Context.selectedCatNodes1) {
      axios
        .get(
          `https://tombaugh.tech/api/${catNodes1Context.selectedCatNodes1}/nodes2/`
        )
        .then((res) => {
          setCatNodes2(res.data);
        });
    } else {
        setCatNodes2([]);
    }
  }, [catNodes1Context.selectedCatNodes1]);


  const handleCatNodes2Change = (selectedValue) => {
    setSelectedCatNodes2(selectedValue);
  };

 
  return (
    <CatNodes2Context.Provider
      value={{
        catNodes2,
        setCatNodes2,
        selectedCatNodes2,
        handleCatNodes2Change,
      }}
    >
      {children}
    </CatNodes2Context.Provider>
  );
};