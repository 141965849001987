//src\components\manufacturers\Manufactures.js

import React from "react";
import {Segment, Header, Grid, Divider, Modal, Button, Icon} from "semantic-ui-react";
import { useManufLcvContext } from "../../contexts/vehicles/manuf-lcv-context";
import { useModelLcvContext } from "../../contexts/vehicles/model-lcv-context";
import { useTypeLcvContext } from "../../contexts/vehicles/type-lcv-context";
import { useExtraCarsLcvContext } from "../../contexts/vehicles/extra-info-lcv-context";

const SearchAuto = () => {
  const manufContext = useManufLcvContext();
  const modelContext = useModelLcvContext();
  const typeContext = useTypeLcvContext();
  const extraCarsLcvContext = useExtraCarsLcvContext();
  const [open, setOpen] = React.useState(false);

  return (
    <Segment basic>
      <Grid textAlign={"center"} columns={1}>
        <Grid.Column stretched={true} >
          <Header as="h4">Αναζήτηση Οχήματος</Header>
          <select
            value={manufContext.selectedManufacturer}
            onChange={manufContext.handleManufacturerChange}
          >
            <option value=""> Επιλογή Κατασκευαστή </option>
            {manufContext.manufacturers.map((manufacturer) => (
              <option key={manufacturer.manuf_id} value={manufacturer.manuf_id}>
                {manufacturer.mancode}
              </option>
            ))}
          </select>
          <select
            value={modelContext.selectedModel}
            onChange={modelContext.handleModelChange}
          >
            <option value=""> Επιλογή Μοντέλου </option>
            {modelContext.vehicleModels.map((vehicleModel) => (
              <option
                key={vehicleModel.model_series_id}
                value={vehicleModel.model_series_id}
              >
                {vehicleModel.descrByYear}
              </option>
            ))}
          </select>
          <select
            value={typeContext.selectedType}
            onChange={typeContext.handleTypeChange}
          >
            <option value=""> Επιλογή Τύπου </option>
            {typeContext.vehicleTypes.map((vehicleType) => (
              <option
                key={vehicleType.vehicle_type_id}
                value={vehicleType.vehicle_type_id}
              >
                {vehicleType.descrYear}
              </option>
            ))}
          </select>
          <Divider horizontal> AND </Divider>
          <Modal
                basic
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={
                <Button animated='vertical' color='orange'>
                <Button.Content hidden>Extra Info</Button.Content>
                <Button.Content visible>
                <Icon name='car' />
                </Button.Content>
                </Button>
              }
              >
                <Header icon='car' content='Πληροφορίες οχήματος' color='orange' />
                <Modal.Content>
                  {extraCarsLcvContext.carsInfo.map((carInfo) => (
                    <ul>
                      <li>{"Κατασκευαστής : "}{carInfo.manufacturer}</li>
                      <li>{"Μοντέλο / Τύπος : "}{carInfo.modelAndType}</li>
                      <li>{"Αριθ. βαλβύδων : "}{carInfo.valves}</li>
                      <li>{"KW : "}{carInfo.kw}</li>
                      <li>{"Ιπποδύναμη : "}{carInfo.hp}</li>
                      <li>{"Κυβικά : "}{carInfo.cc}</li>
                      <li>{"Αριθ. κυλίνδρων : "}{carInfo.cylinders}</li>
                      <li>{"Τύπος καυσίμου : "}{carInfo.fueltype}</li>
                      <li>{"Τύπος αμαξώματος : "}{carInfo.bodytype}</li>
                      <li>{"Κινητήρας/ες : "}{carInfo.listengines}</li>
                    </ul>
                  ))}
                </Modal.Content>
              </Modal>

        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default SearchAuto;
