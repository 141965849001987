//manuf-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import handleChangeEvent from "../../events/handleChangeEvent";

const ManufContext = React.createContext();

export const useManufContext = () => useContext(ManufContext);

export const ManufContextProvider = ({ children }) => {
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);

  useEffect(() => {
    axios.get("https://tombaugh.tech/api/manufacturers/").then((res) => {
      setManufacturers(res.data);
    });
  }, []);

  const handleManufacturerChange = (event) => {
    handleChangeEvent(event, setSelectedManufacturer);
  };

  return (
    <ManufContext.Provider
      value={{
        manufacturers,
        selectedManufacturer,
        handleChangeEvent,
        handleManufacturerChange,
      }}
    >
      {children}
    </ManufContext.Provider>
  );
};
