//model-context.js
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useManufLcvContext } from "./manuf-lcv-context";

const ModelLcvContext = React.createContext();

export const useModelLcvContext = () => useContext(ModelLcvContext);

export const ModelLcvContextProvider = ({ children }) => {
  const [vehicleModels, setVehicleModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null); //selectedModel
  const manufLcvContext = useManufLcvContext();

  useEffect(() => {
    if (manufLcvContext.selectedManufacturer) {
      axios
        .get(
          `https://tombaugh.tech/api/manufacturers-lcv/${manufLcvContext.selectedManufacturer}/vehicle-models`
        )
        .then((res) => {
          setVehicleModels(res.data);
        });
    } else {
      setVehicleModels([]);
    }
  }, [manufLcvContext.selectedManufacturer]);

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  return (
    <ModelLcvContext.Provider
      value={{
        vehicleModels,
        setVehicleModels,
        selectedModel,
        handleModelChange,
      }}
    >
      {children}
    </ModelLcvContext.Provider>
  );
};
