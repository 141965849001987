// contexts\vehicles\type-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import handleChangeEvent from "../../events/handleChangeEvent";
import { useModelContext } from "./model-context";

const TypeContext = React.createContext();

export const useTypeContext = () => useContext(TypeContext);

export const TypeContextProvider = ({ children }) => {
  const [selectedType, setSelectedType] = useState(null);
  const modelContext = useModelContext();
  const [isLoading, setIsLoading] = useState(true); //new
  const [error, setError] = useState(null); //new
  // const [page] = useState(0); // new

  const [vehicleTypes, setVehicleTypes] = useState([]);


  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    // Fetch data using async/await with Axios
    const fetchUsingAsyncAwaitWithAxios = async () => {
      try {
        if (modelContext.selectedModel) {
          const BASE_URL = `https://tombaugh.tech/api/vehicle-models/${modelContext.selectedModel}/vehicle-types`;

          const { data } = await axios.get(`${BASE_URL}`, {
            signal,
          });
          setVehicleTypes(data); // Set the fetched data
        } else {
          setVehicleTypes([]);
          setError(null); // Clear any previous errors
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Fetch aborted"); // Log a message when the request is intentionally aborted
          return; // Exit the function to prevent further error handling
        }
        setError(error.message);
      } finally {
        setIsLoading(false);
      } // Turn off loading indicator
    };

    // Trigger fetching method on component mount
    fetchUsingAsyncAwaitWithAxios();

    return () => {
      abortController.abort();
      setIsLoading(true); // Reset loading state
    };
  }, [modelContext.selectedModel]); // Run the effect only once on component mount

  const handleTypeChange = (event) => {
    handleChangeEvent(event, setSelectedType);
  };

  return (
    <TypeContext.Provider
      value={{
        vehicleTypes,
        handleChangeEvent,
        handleTypeChange,
        selectedType,
      }}
    >
      {/* Display errors if any */}
      {error && <div>{error}</div>}
      {isLoading ? (
        <div>
          {/* <button onClick={() => setPage(page + 1)}>Loading... ({page})</button> */}
          <h3>Loading...</h3>
        </div>
      ) : (
        children // Render children once data is fetched
      )}
    </TypeContext.Provider>
  );
};