import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";  

const ProdGenListContext =  React.createContext();

export const useProdGenListContext = () => useContext(ProdGenListContext);


export const ProdGenListContextProvider = ({ children }) => {
  const [prodGens, setProdGens] = useState([]);

  const handleAPICall = (selectedValue) => {
    axios
      .get(`https://tombaugh.tech/api/${selectedValue}/prodgenlist/`)
      .then((response) => {
        setProdGens(response.data);
      })
      .catch((error) => {
      });
  };

  const location = useLocation();
  const selectedValue = (location.state && location.state.selectedValue) || "";

  useEffect(() => {
    if (selectedValue) {
      handleAPICall(selectedValue);
    }
  }, [selectedValue]); // Set loading to false after data is received

  console.log("prodGens in context:", prodGens);

  return (
    <ProdGenListContext.Provider value={{ handleAPICall, setProdGens, prodGens }}>
      {children}
    </ProdGenListContext.Provider>
  );
};

