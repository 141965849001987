// src/components/show_table/CardBarcode.js

//ShowCard.js
import React from "react";
import {Card, Icon, Image} from "semantic-ui-react";
import { useProductByLcvContext } from "../../contexts/show_table/prod-table-lcv-context";

const CardBarcode = () => {
  const productByLcvContext = useProductByLcvContext();
  const products = productByLcvContext.products;
  const photos= productByLcvContext.photos;

  return (
    <>
      {products && products.length > 0
        ? (products.map((product) => (
            // (photos.map((photo) => (
            <Card key={product.product_id}>
           {photos && photos.length > 0 ? (
             photos.map(photo => (
              <Image
                src={`https://res.cloudinary.com/dov4oyrcf/image/upload/v1699547546/Images/${photo.brand_id}/${photo.docname}.jpg`} wrapped ui={false}
              />
            ))) : null}
              <Card.Content>
                <Card.Header>
                  {product.product_id} - {product.barcodesByBrand}{" "}
                  <Icon name="industry" />
                </Card.Header>
                <Card.Meta>
                  <Icon name="barcode" />
                  <span className="date">Barcode : </span>
                </Card.Meta>
                <Card.Description>{product.ean_id}</Card.Description>
              </Card.Content>
            </Card>
          )))
         // )))
        : null}

    </>
  );
};

export default CardBarcode;


// import React, { useState } from "react";
// import { Segment, Grid, Card, Icon } from "semantic-ui-react";
// import { useProductByVehicleContext } from "../../contexts/show_table/prod-table-context";
//
// const CardBarcode = () => {
//   const { barcodes } = useProductByVehicleContext();
//   const [showCard, setShowCard] = useState(false);
//
//   // useEffect(() => {
//   //   if (barcodes && barcodes.length > 0) setShowCard(true);
//   // }, [barcodes]);
//
//   return (
//     <Segment>
//       {showCard && (
//         <Grid.Column floated="right" width={5}>
//           {barcodes && barcodes.length > 0
//             ? barcodes.map((barcode) => (
//                 <Card>
//                   <Card.Content>
//                     <Card.Header>
//                       {barcode.product_id} - {barcode.barcodesByBrand}{" "}
//                       <Icon name="industry" />
//                     </Card.Header>
//                     <Card.Meta>
//                       <Icon name="barcode" />
//                       <span className="date">Barcode : </span>
//                     </Card.Meta>
//                     <Card.Description>{barcode.ean_id}</Card.Description>
//                   </Card.Content>
//                 </Card>
//               ))
//             : null}
//         </Grid.Column>
//       )}
//     </Segment>
//   );
// };
//
// export default CardBarcode;
