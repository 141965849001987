import React from "react";
import {
  List,
  ListItem,
  ListIcon,
  ListHeader,
  ListContent,
  Placeholder,
} from "semantic-ui-react";
import { useProductExtraContext } from "../../contexts/additional_info/products-extra-contexts";


const ListWithTitle = ({ title, items, loading }) => (

    <List divided relaxed>
    <ListHeader>{title}</ListHeader>  
    {loading ? (
      <ListItem>
        <Placeholder>
          <Placeholder.Paragraph>Τα αποτελέσματα φορτώνονται...</Placeholder.Paragraph>
        </Placeholder>
      </ListItem>

    ) : items.length > 0 ? (

      items.map((item, index) => (
        <ListItem key={index}>
          <ListIcon name="info" size="small" verticalAlign="middle" />
          <ListContent>{item.name} : {item.value}</ListContent>
        </ListItem>
        
      ))
    ) : (

        <ListItem> 
          <Placeholder>
            <Placeholder.Paragraph>Δεν υπάρχουν αποτελέσματα</Placeholder.Paragraph>
            </Placeholder>
        </ListItem>
      )}
      
    </List>
  );


const ProductInfo = () => {
    const {
        trades,
        supersedes,
        criteriaInfo,
        productsCard,
        cardLoading,
        tradesLoading,
        supersedesLoading,
        criteriaLoading
      } = useProductExtraContext();


// Assuming we want to use a field from the first trade item as the main title

const mainTitle = productsCard.length > 0  ? `${productsCard[0].product_id} - ${productsCard[0].barcodesByBrand}` : criteriaInfo.length > 0  ? `${criteriaInfo[0].product_id} - ${criteriaInfo[0].brand_id}` : "";

    return (
        <>
            {/* Main title above all lists */}
            <h4>{mainTitle}</h4>
            <ListWithTitle title="BARCODES" items={productsCard.map(barcode => ({ name: barcode.ean_id, value: "" }))} loading={cardLoading}/>
            <ListWithTitle title="ΑΡΙΘΜΟΣ/ΟΙ ΕΜΠΟΡΙΟΥ" items={trades.map(trade => ({ name: trade.trade_code, value: trade.countrycode }))} loading={tradesLoading}/>
            <ListWithTitle title="ΚΩΔΙΚΟ/ΟΙ ΑΝΤΙΚΑΤΑΣΤΑΣΗΣ" items={supersedes.map(supersede => ({ name: supersede.supersedes, value: supersede.countrycode }))} loading={supersedesLoading}/>
            <ListWithTitle title="EXTRA ΠΛΗΡΟΦΟΡΙΕΣ" items={criteriaInfo.map(criterioInfo => ({ name: criterioInfo.critirio, value: criterioInfo.value }))} loading={criteriaLoading}/>
        </>
);
};


export default ProductInfo;


