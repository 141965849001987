//src\contexts\categories_tree\favorite-categories.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
// import { useCatNodes1Context } from "./cat-node1";

const FavCategoriesContext = React.createContext();

export const useFavCategoriesContext = () => useContext(FavCategoriesContext);

export const FavCategoriesContextProvider = ({ children }) => {
  const [favCategories, setFavCategories] = useState([]);
  const [selectedFavCategories, setSelectedFavCategories] = useState([]);
  // const catNodes1Context = useCatNodes1Context();

  useEffect(() => {
    const fetchFavCategories = async () => {
      try {
        const response = await axios.get(
          `https://tombaugh.tech/api/favCategories/`
        );
        setFavCategories(response.data);
      } catch (err) {
        console.error("Failed to fetch favCategories", err);
        setFavCategories([]); // Ensure it's at least an empty array on error
      }
    };

    fetchFavCategories();
  }, []);

  const handleFavCategories = (selectedValue) => {
    setSelectedFavCategories(selectedValue);
  };

  return (
    <FavCategoriesContext.Provider
      value={{
        favCategories,
        selectedFavCategories,
        handleFavCategories,
      }}
    >
      {children}
    </FavCategoriesContext.Provider>
  );
};