//ShowOeList.js
import React, { useMemo, useState } from "react";
import { Grid } from "semantic-ui-react";

import { useProductByEngineLcvContext } from "../../contexts/show_table/prod-table-lcv-eng-context";
import Pagination from "../../events/Pagination";

let PageSize = 10;
const ShowOeLcvEngineList = () => {
  const productByEngineContext = useProductByEngineLcvContext();
  const oes = productByEngineContext.oes;

  const [currentPage, setCurrentPage] = useState(1);
  // const [currentTableData, setCurrentTableData] = useState([]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return oes.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, oes]);

  return (
    <Grid.Column tablet={8} computer={3}>
      <div>
        {oes && oes.length > 0 ? (
          <ul>
            <h3>OE List</h3>
            {currentTableData.map((oe) => (
              <li key={oe.oe}>{oe.oe}-{oe.vehicles}</li>
            ))}
          </ul>
        ) : null
        }
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={oes.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </Grid.Column>
  );
};

export default ShowOeLcvEngineList;
