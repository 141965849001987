// CustomArrow.js
import React from 'react';

const CustomArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'grey', // Customize your arrow color
        borderRadius: '50%',
      }}
      onClick={onClick}
    />
  );
};

export default CustomArrow;