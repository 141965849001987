//  src\pages\Passenger.js

import React from "react";
import { useLocation } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import ShowManufModelTypes from "../components/vehicles/ManufModelTypes";
import ShowEngineTypesOfModel from "../components/vehicles/EngineTypesOfModel";
import { TypeContextProvider } from "../contexts/vehicles/type-context";
import { EngModelTypeContextProvider } from "../contexts/engines/eng-model-type-context";
// import { useNavigation } from "../contexts/history/navigation-history";

const Passenger = () => {
  const location = useLocation();
  // const { pushState } = useNavigation();
  const selectedComponent = (location.state && location.state.selectedComponent) || "";


  // useEffect(() => {
  //   // Push the current state to the navigation context when the component mounts or updates
  //   pushState({ selectedComponent });
  // }, [selectedComponent, pushState]);


  return (
    <TypeContextProvider>
     <EngModelTypeContextProvider>
        <Segment basic >
            {selectedComponent === "ShowManufModelTypes" ? <ShowManufModelTypes /> : null}
            {selectedComponent === "ShowEngineTypesOfModel" ? <ShowEngineTypesOfModel /> : null}
          </Segment>
        </EngModelTypeContextProvider>
        </TypeContextProvider>
  );
};

export default Passenger;