//type-context.js
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useEngineLcvContext } from "./engine-lcv-context";

const ModelTypeLcvContext = React.createContext();

export const useModelTypeLcvContext = () => useContext(ModelTypeLcvContext);

export const ModelTypeLcvContextProvider = ({ children }) => {
  const [modelTypesLcv, setModelTypesLcv] = useState([]);
  const [selectedModelTypeLcv, setSelectedModelTypeLcv] = useState(null);
  const engineLcvContext = useEngineLcvContext();

  useEffect(() => {
    if (engineLcvContext.selectedLcvEngine) {
      axios
        .get(
          `https://tombaugh.tech/api/${engineLcvContext.selectedLcvEngine}/models-types-lcv`
        )
        .then((res) => {
          setModelTypesLcv(res.data);
        });
    } else {
      setModelTypesLcv([]);
    }
  }, [engineLcvContext.selectedLcvEngine]);

  const handleModelTypeLcvChange = (event) => {
    setSelectedModelTypeLcv(event.target.value);
  };

  return (
    <ModelTypeLcvContext.Provider
      value={{
        modelTypesLcv,
        handleModelTypeLcvChange,
        setModelTypesLcv,
        selectedModelTypeLcv,
      }}
    >
      {children}
    </ModelTypeLcvContext.Provider>
  );
};
