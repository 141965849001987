//src\contexts\categories\gen-category-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useSubCategoryContext } from "./sub-category-context";
import { useCategoryContext } from "./category-context";

const GenCategoryContext = React.createContext();

export const useGenCategoryContext = () => useContext(GenCategoryContext);

export const GenCategoryContextProvider = ({ children }) => {
  const [gen_categories, setGen_categories] = useState([]);
  const [selectedGen_category, setSelectedGen_category] = useState(null);
  const subCategoryContext = useSubCategoryContext();
  const categoryContext = useCategoryContext();

  useEffect(() => {
    if (subCategoryContext.selectedSub_category) {
      axios
        .get(
        `https://tombaugh.tech/api/${categoryContext.selectedCategory}/${subCategoryContext.selectedSub_category}/gen_categories`
        )
        .then((res) => {
          setGen_categories(res.data);
        });
    } else {
      setGen_categories([]);
    }
  }, [
    subCategoryContext.selectedSub_category,
    categoryContext.selectedCategory,
  ]);

  const handleGenCategoriesChange = (event) => {
    setSelectedGen_category(event.target.value);
  };

  return (
    <GenCategoryContext.Provider
      value={{
        gen_categories,
        handleGenCategoriesChange,
        setGen_categories,
        selectedGen_category,
      }}
    >
      {children}
    </GenCategoryContext.Provider>
  );
};
