import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";   //  new   
import { useProdGenListContext } from "../categories_tree/prod-gen-list";


const ProductsContext =  React.createContext();

export const useProductsContext = () => useContext(ProductsContext);


export const ProductsContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const prodGensContext = useProdGenListContext();
  const location = useLocation();
  // const selectedVehicle = (location.state && location.state.selectedVehicle) || "";
  const selectedVehicle = location.state && location.state.selectedVehicle;
  const vehicleTypeId = selectedVehicle ? selectedVehicle.vehicle_type_id : "";
  const [search, SetSearch] = useState("");
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    if (vehicleTypeId && prodGensContext.prodGens )  {

      axios
      .get(`https://tombaugh.tech/api/${vehicleTypeId}/productVehicles/${prodGensContext.prodGens.map(item => item.prod_gen).join(",")}/products/`)
        .then((res) => {
          setProducts(res.data);
          setFilter(res.data);
        });
    } else {
      setProducts([]);
    }
  }, [vehicleTypeId, prodGensContext.prodGens]);

  useEffect(() => {
    const result = products.filter((item) => {
      return item.brands.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
  }, [search]);


  return (
    <ProductsContext.Provider value={{ 
      products, 
      setProducts,   
      filter,
      search,
      SetSearch,  
       }}>
      {children}
    </ProductsContext.Provider>
  );
};

