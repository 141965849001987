//  src\components\products\SearchByBrandS.js

import React from "react";
// import axios from "axios";
import {
  ListItem,
  ListHeader,
  ListContent,
  List,
  Image,
  Label,
  Segment,
  SegmentGroup,
  Button,
  Message
} from 'semantic-ui-react'
import { useBrandsOfProductsContext } from "../../contexts/products/brandsOfProducts";
import { HiDotsVertical } from "react-icons/hi";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import { useProductExtraContext } from "../../contexts/additional_info/products-extra-contexts";
import DataTable from "react-data-table-component";
import ShowProducts from "./Products";
import { Oval } from 'react-loader-spinner';

const SearchByBrand = () => {

  const {
    brandsbyProd,
    brandImages,
    filteredProducts,
    showProductsComponent,
    setShowProductsComponent,
    handleFilterByBrand,
    loading // Destructure loading state from context
} = useBrandsOfProductsContext();

const {
    handleTableRowClick,
    handle2TableRowClick,
    handle3TableRowClick,
    setActiveComponent,
    activeComponent
} = useProductExtraContext();

     
  const tableHeaderstyle = {
    rows: {
      style: {
        maxHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#ccc",
      },
    },
  };

  
  const columns = [
    {
      name: "Κωδικός Προϊόντος",
      selector: (row) => row.product_id,
      sortable: true,
    },
    {
      name: "Μάρκα",
      selector: (row) => row.brands,
      sortable: true,
    },
    {
      button: true,
      cell: (row) => (
        <Menu>
          <MenuButton>
            {" "}
            <HiDotsVertical />{" "}
          </MenuButton>
          <MenuList>
          <MenuItem
              onClick={(event) =>
                handle3TableRowClick(event, row.product_id, row.brand_id) 
              }
            >
              Λίστα με OE
            </MenuItem>
          <MenuItem
              onClick={(event) => {
                handle2TableRowClick(event, row.product_id, row.brand_id);
              }}
            >
              Πληροφορίες
            </MenuItem>
            <MenuItem
              onClick={(event) =>
                handleTableRowClick(event, row.product_id, row.brand_id) 
              }
            >
              Φωτογραφία
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    },
  ];

  const toggleShowProducts = () => {
    setShowProductsComponent(!showProductsComponent);
    setActiveComponent(!activeComponent);
  };


  return (  
      <SegmentGroup horizontal basic>
          {loading ? (
              <Segment basic>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
              <Oval color="#00BFFF" height={60} width={60} />
              </div>
              </Segment>
          ) : (
               <>
                    {brandsbyProd.length > 0 ? (
                        <>
                            <Segment basic>
                                <Label size='large' color='blue'>Μάρκες - Κατάλογος </Label>
                                <List animated>
                                    {brandsbyProd.map((brand) => (
                                        <ListItem
                                            key={brand.brand_id}
                                            onClick={() => {
                                                console.log('Filtering by brand_id:', brand.brand_id);
                                                handleFilterByBrand(brand.brand_id);
                                            }}
                                        >
                                             <Image
                                                  src={brandImages[brand.brand_id]}
                                                  wrapped
                                                  ui={false}
                                                  onError={(e) => e.target.style.display = 'none'} // Hide image if not found
                                                />
                                              <ListContent>
                                                  <ListHeader as='a'>{brand.brands}</ListHeader>
                                              </ListContent>
                                          </ListItem>
                                      ))}
                                  </List>
                                </Segment>
                                <Segment basic>
                                    <Button
                                        onClick={toggleShowProducts}
                                        color="blue"
                                        content={showProductsComponent
                                            ? "Πίσω στα προϊόντα ανα μάρκα" : "Εμφάνιση όλων των προϊόντων"
                                        }
                                        icon={showProductsComponent ? "arrow left" : "eye"}
                                    />
                                    {showProductsComponent ? (
                                        <ShowProducts />
                                    ) : filteredProducts.length > 0 ? (
                                        <DataTable
                                            title="Προϊόντα"
                                            customStyles={tableHeaderstyle}
                                            columns={columns}
                                            data={filteredProducts}
                                            highlightOnHover
                                            pagination
                                        />
                                    ) : null}
                                </Segment>
                            </>
                        ) : (
                            <Message warning compact>
                                <Message.Header>Δεν υπάρχουν αποτελέσματα</Message.Header>
                                <p>Δεν υπάρχουν διαθέσιμα προϊόντα για το συγκεκριμένο τύπο αυτοκινήτου.</p>
                            </Message>
                        )}
                    </>
                )
            }
        </SegmentGroup>
    );
};


export default SearchByBrand;