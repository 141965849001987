//src\contexts\prod-table-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useTypeLcvContext } from "../vehicles/type-lcv-context";
import { useGenCategoryContext } from "../categories/gen-category-context";
import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import { HiDotsVertical } from "react-icons/hi";
// import { useBarcodeContext } from "./barcode-context"; //new

const ProductByLcvContext = React.createContext();

export const useProductByLcvContext = () => useContext(ProductByLcvContext);

export const ProductByLcvContextProvider = ({ children }) => {
  const [productVehicles, setProductVehicles] = useState([]);
  const typeLcvContext = useTypeLcvContext();
  const gen_categoryContext = useGenCategoryContext();
  // const barcodeContext = useBarcodeContext();

  const [showCard, setShowCard] = useState(false);
  const [products, setCardDetails] = useState([]);

  const [showOeList, setShowOeList] = useState(false);
  const [oes, setOes] = useState([]);

  const [showPhotos, setShowPhotos] = useState(false);
  const [photos, setPhotos] = useState([]);

  const [search, SetSearch] = useState("");
  const [filter, setFilter] = useState([]);

  const columns = [
    {
      name: "Κωδικός Προϊόντος",
      selector: (row) => row.product_id,
      sortable: true,
    },
    {
      name: "Μάρκα",
      selector: (row) => row.brands,
      sortable: true,
    },
    {
      button: true,
      cell: (row) => (
        <Menu>
          <MenuButton>
            {" "}
            <HiDotsVertical />{" "}
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={(event) =>
                handle2TableRowClick(event, row.product_id, row.brand_id)
              }
            >
              ΟΕ
            </MenuItem>
            <MenuItem
              onClick={(event) =>
                handleTableRowClick(event, row.product_id, row.brand_id)
              }
            >
              Φωτογραφία
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    },
  ];

  const getProductsByCategory = async () => {
    if (
      typeLcvContext.selectedType &&
      gen_categoryContext.selectedGen_category
    ) {
      const response = await axios.get(
        `https://tombaugh.tech/api/${typeLcvContext.selectedType}/productVehicles/${gen_categoryContext.selectedGen_category}/productsByCategories/`
      );

      await setProductVehicles(response.data);
      setFilter(response.data);
    } else {
      setProductVehicles([]);
    }
  };

  useEffect(() => {
    getProductsByCategory();
  }, [gen_categoryContext.selectedGen_category, typeLcvContext.selectedType]);

  useEffect(() => {
    const result = productVehicles.filter((item) => {
      return item.brands.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
  }, [search]);

  const handleTableRowClick = (event, product_id, brand_id) => {
    setShowOeList(false);

    if (product_id && brand_id > 0) {
      setShowCard(true);

      const product_id_new = product_id.replace("/", "");

      // First API call
      fetch(`https://tombaugh.tech/api/${product_id_new}/barcode/${brand_id}`)
        .then((res) => res.json())
        .then((data) => {
          setCardDetails(data);
        });

      // Second API call
      fetch(`https://tombaugh.tech/api/${product_id_new}/images/${brand_id}`)
        .then((res) => res.json())
        .then((data) => {
          setPhotos(data);
        });
    } else {
      setShowCard(false);
      setShowPhotos(false);
    }
  };

  const handle2TableRowClick = (event, product_id, brand_id) => {
    setShowCard(false);

    if (product_id && brand_id > 0) {
      setShowOeList(true);

      const product_id_new = product_id.replace("/", "");

      // First API call
      fetch(`https://tombaugh.tech/api/${product_id_new}/${brand_id}/oe`)
        .then((res) => res.json())
        .then((data) => {
          setOes(data);
        });
    } else {
      setShowOeList(false);
    }
  };

  return (
    <ProductByLcvContext.Provider
      value={{
        products,
        showCard,
        oes,
        showOeList,
        productVehicles,
        setProductVehicles,
        getProductsByCategory,
        photos,
        showPhotos,
        columns,
        filter,
        search,
        SetSearch,
        handleTableRowClick,
      }}
    >
      {children}
    </ProductByLcvContext.Provider>
  );
};

export default ProductByLcvContextProvider;
