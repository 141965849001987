//src\components\show_table\ProductsByVehicle.js

import {Segment, Grid, Button} from "semantic-ui-react";
import DataTable from "react-data-table-component";
import { useProductByEngineLcvContext } from "../../contexts/show_table/prod-table-lcv-eng-context";
import { useGenCategoryContext } from "../../contexts/categories/gen-category-context";
import CardEngineBarcode from "./BarcodeEngineCard";
import ShowOeLcvEngineList from "./ShowOeLcvEngineList";
import React from "react";
import differenceBy from "lodash/differenceBy";


const ShowProductsByLcvEngine = () => {
  const genCategoryContext = useGenCategoryContext();
  const productByEngineLcvContext = useProductByEngineLcvContext();
  const productVehicles = productByEngineLcvContext.productVehicles;
  const columns = productByEngineLcvContext.columns;
  const filter = productByEngineLcvContext.filter;
  const search = productByEngineLcvContext.search;
  const SetSearch = productByEngineLcvContext.SetSearch;

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [data, setData] = React.useState(productVehicles);

  const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

  const contextActions = React.useMemo(() => {
		const handleOes = () => {

			if (window.confirm(`Θα πραγματοποιηθεί σύνθετη αναζήτηση ΟΕ για τους κωδικούς:\r ${selectedRows.map(r => r.product_id)}?`)) {
				setToggleCleared(!toggleCleared);
				setData(differenceBy(data, selectedRows, 'title'));
			}
		};

		return (
                <Button
                  basic
                  onClick={handleOes}
                  color='blue'
                  content='ΣΥΝΘΕΤΗ ΑΝΑΖΗΤΗΣΗ'
                  icon='filter'
                  label={{
                    as: 'a',
                    basic: true,
                    color: 'blue',
                    pointing: 'left',
                    content: 'ΟΕ',
                  }}
                />
		);
	}, [data, selectedRows, toggleCleared]);


  const tableHeaderstyle={
      rows: {
        style: {
            maxHeight: '65px', // override the row height
        },
    },
    headCells:{
        style:{
            fontWeight:"bold",
            fontSize:"14px",
            backgroundColor:"#ccc"

        },
    },
   }


  return (
    <Segment basic>
      <Grid stretched={true}>
        <Grid.Column tablet={4} computer={8}>
          {genCategoryContext.selectedGen_category && productVehicles.length > 0 ?
            (
           <DataTable
            title="Προϊόντα"
            customStyles={ tableHeaderstyle }
            columns={ columns }
            data={ filter }
            pagination
            selectableRows
            fixedHeader
            contextActions={ contextActions }
			onSelectedRowsChange={ handleRowSelected }
            clearSelectedRows={ toggleCleared }
            highlightOnHover
            subHeader
             subHeaderComponent={
                <input type="text"
                className="form-control"
                placeholder="Αναζήτηση Μάρκας ... "
                value={ search }
                onChange={(e)=>SetSearch(e.target.value)}
                />
             }
             subHeaderAlign="left"
            />
          )
              : genCategoryContext.selectedGen_category && productVehicles.length === 0 ? (
            <div>
               <p>ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΠΟΤΕΛΕΣΜΑΤΑ</p>
            </div>
         ) : null
          }

        </Grid.Column>

          <Segment basic>
            <Grid.Column tablet={8} computer={4}>
            {productByEngineLcvContext.showCard && <CardEngineBarcode />}

            {productByEngineLcvContext.oes && <ShowOeLcvEngineList />}
          </Grid.Column>
          </Segment>
      </Grid>
    </Segment>
  );
};

export default ShowProductsByLcvEngine;
