//manuf-context.js
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

const ManufLcvContext = React.createContext();

export const useManufLcvContext = () => useContext(ManufLcvContext);

export const ManufLcvContextProvider = ({ children }) => {
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);

  useEffect(() => {
    axios.get("https://tombaugh.tech/api/manufacturers-lcv/").then((res) => {
      setManufacturers(res.data);
    });
  }, []);

  const handleManufacturerChange = (event) => {
    setSelectedManufacturer(event.target.value);
  };

  return (
    <ManufLcvContext.Provider
      value={{
        manufacturers,
        selectedManufacturer,
        handleManufacturerChange,
      }}
    >
      {children}
    </ManufLcvContext.Provider>
  );
};
