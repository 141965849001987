//type-context.js
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useModelLcvContext } from "./model-lcv-context";
import handleChangeEvent from "../../events/handleChangeEvent";

const TypeLcvContext = React.createContext();

export const useTypeLcvContext = () => useContext(TypeLcvContext);

export const TypeLcvContextProvider = ({ children }) => {
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const modelLcvContext = useModelLcvContext();

  useEffect(() => {
    if (modelLcvContext.selectedModel) {
      axios
        .get(
          `https://tombaugh.tech/api/vehicle-models/${modelLcvContext.selectedModel}/vehicle-types`
        )
        .then((res) => {
          setVehicleTypes(res.data);
        });
    } else {
      setVehicleTypes([]);
    }
  }, [modelLcvContext.selectedModel]);

  // const handleChangeEvent = (event) => {
  //   handleChangeEvent(event, selectedType);
  // };
  const handleTypeChange = (event) => {
    handleChangeEvent(event, setSelectedType);
  };

  return (
    <TypeLcvContext.Provider
      value={{
        vehicleTypes,
        handleTypeChange,
        handleChangeEvent,
        setVehicleTypes,
        selectedType,
      }}
    >
      {children}
    </TypeLcvContext.Provider>
  );
};
