//  src\contexts\vehicles\model-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useManufContext } from "./manuf-context";
import handleChangeEvent from "../../events/handleChangeEvent";

const ModelContext = React.createContext();

export const useModelContext = () => useContext(ModelContext);

export const ModelContextProvider = ({ children }) => {
  const [vehicleModels, setVehicleModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const manufContext = useManufContext();

  useEffect(() => {
    if (manufContext.selectedManufacturer) {
      axios
        .get(
          `https://tombaugh.tech/api/manufacturers/${manufContext.selectedManufacturer}/vehicle-models`
        )
        .then((res) => {
          setVehicleModels(res.data);
        });
    } else {
      setVehicleModels([]);
    }
  }, [manufContext.selectedManufacturer]);

  const handleModelChange = (event) => {
    handleChangeEvent(event, setSelectedModel);
  };

  return (
    <ModelContext.Provider
      value={{
        vehicleModels,
        selectedModel,
        handleModelChange,
        handleChangeEvent,
      }}
    >
      {children}
    </ModelContext.Provider>
  );
};
