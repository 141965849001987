// src/components/categories_tree/CategoryIcons.js

import React from 'react';
import { Popup } from 'semantic-ui-react';


// Import all images from the images directory

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}


const images = importAll(require.context('../../images', false, /\.(png|jpe?g|svg)$/));

const CategoryIcons = ({ categories, onCategoryClick }) => {

  return (

    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

      {categories.map((category, index) => (

        <Popup

          key={category.node_id1}

          content={category.str_text1}

          trigger={

            <div onClick={() => onCategoryClick(index, category.node_id1, 0)}>

              <img 

                src={images[`${category.node_id1}.png`]} 

                alt={`Icon for ${category.node_id1}`} 

                style={{ width: '65px', height: '65px' }} 

              />

            </div>

          }

        />

      ))}

    </div>

  );

};



export default CategoryIcons;