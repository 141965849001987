//src\pages\LightCommercial.js

import React from "react";

import { Grid, Segment, Header } from "semantic-ui-react";
import SearchAuto from "../components/sidebar/ManufacturesLcv";
import SearchCategory from "../components/categories/Products";
import ShowProductsByLcv from "../components/show_table/ProductsByLcv";
import ShowProductsByLcvEngine from "../components/show_table/ProductByLcvEngine";
import SearchLcvEngine from "../components/engines/SearchLcvEngine";

import { ManufLcvContextProvider } from "../contexts/vehicles/manuf-lcv-context";
import { ModelLcvContextProvider } from "../contexts/vehicles/model-lcv-context";
import { TypeLcvContextProvider } from "../contexts/vehicles/type-lcv-context";
import { ExtraCarsLcvContextProvider } from "../contexts/vehicles/extra-info-lcv-context";
import { CategoryContextProvider } from "../contexts/categories/category-context";
import { SubCategoryContextProvider } from "../contexts/categories/sub-category-context";
import { GenCategoryContextProvider } from "../contexts/categories/gen-category-context";
import { EngineLcvContextProvider } from "../contexts/engines/engine-lcv-context";
import { ModelTypeLcvContextProvider } from "../contexts/engines/model-type-lcv-context";
import { ProductByLcvContextProvider } from "../contexts/show_table/prod-table-lcv-context";
import { ProductByEngineLcvContextProvider } from "../contexts/show_table/prod-table-lcv-eng-context";

function LightCommercialPage() {
  return (
    <>
      <Header as="h2" textAlign={"center"}>
        Ελαφριά Εμπορικά Οχήματα
      </Header>
      {/*<h1> Ελαφριά Εμπορικά Οχήματα </h1>*/}

      <ManufLcvContextProvider>
        <ModelLcvContextProvider>
          <TypeLcvContextProvider>
            <ExtraCarsLcvContextProvider>
              <EngineLcvContextProvider>
                <ModelTypeLcvContextProvider>
                  <CategoryContextProvider>
                    <SubCategoryContextProvider>
                      <GenCategoryContextProvider>
                        <ProductByLcvContextProvider>
                          <ProductByEngineLcvContextProvider>
                            <Grid columns="equal" divided={true}>
                              <Grid.Row>
                                <Grid.Column width={3}>
                                  <Segment basic>
                                    <SearchAuto />
                                  </Segment>
                                  <Segment basic>
                                    <SearchLcvEngine />
                                  </Segment>
                                  <Segment basic>
                                    <SearchCategory />
                                  </Segment>
                                </Grid.Column>
                                <Grid.Column width={13} stretched={true}>
                                  <Segment basic>
                                    {" "}
                                    <ShowProductsByLcv />{" "}
                                  </Segment>
                                  <Segment basic>
                                    <ShowProductsByLcvEngine />
                                  </Segment>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </ProductByEngineLcvContextProvider>
                        </ProductByLcvContextProvider>
                      </GenCategoryContextProvider>
                    </SubCategoryContextProvider>
                  </CategoryContextProvider>
                </ModelTypeLcvContextProvider>
              </EngineLcvContextProvider>
            </ExtraCarsLcvContextProvider>
          </TypeLcvContextProvider>
        </ModelLcvContextProvider>
      </ManufLcvContextProvider>
    </>
  );
}

export default LightCommercialPage;
