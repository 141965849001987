//  src\contexts\history\history-context.js

import React, { createContext, useContext, useEffect, useState } from "react";

export const HistoryContext = createContext();

export const useHistoryContext = () => {
  return useContext(HistoryContext);
};

export const HistoryProvider = ({ children }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const storedHistory = localStorage.getItem("history");
    if (storedHistory) {
      setHistory(JSON.parse(storedHistory));
    }
  }, []);

  const addHistory = (data) => {
    const updatedHistory = [data, ...history].slice(0, 10); // Prepend the new data and keep only the last 15 choices
    setHistory(updatedHistory);
    localStorage.setItem("history", JSON.stringify(updatedHistory));
  };

  return (
    <HistoryContext.Provider value={{ history, addHistory }}>
      {children}
    </HistoryContext.Provider>
  );
};
