//src\components\manufacturers\Manufactures.js

import React from "react";
import { Segment, Header, Grid } from "semantic-ui-react";
import { useManufLcvContext } from "../../contexts/vehicles/manuf-lcv-context";
import { useEngineLcvContext } from "../../contexts/engines/engine-lcv-context";
import { useModelTypeLcvContext } from "../../contexts/engines/model-type-lcv-context";

const SearchLcvEngine = () => {
  const manufLcvContext = useManufLcvContext();
  const engineLcvContext = useEngineLcvContext();
  const modelTypeLcvContext = useModelTypeLcvContext();

  return (
    <Segment basic>
      <Grid textAlign={"center"} columns={1}>
        <Grid.Column stretched={true}>
          <Header as="h4">Αναζήτηση Κινητήρα</Header>
          <select
            value={manufLcvContext.selectedManufacturer}
            onChange={manufLcvContext.handleManufacturerChange}
          >
            <option value=""> Κατασκευαστής </option>
            {manufLcvContext.manufacturers.map((manufacturer) => (
              <option key={manufacturer.manuf_id} value={manufacturer.manuf_id}>
                {manufacturer.mancode}
              </option>
            ))}
          </select>
          <select
            value={engineLcvContext.selectedLcvEngine}
            onChange={engineLcvContext.handleLcvEngineChange}
          >
            <option value=""> Κινητήρας </option>
            {engineLcvContext.lcvEngines.map((enginelcv) => (
              <option key={enginelcv.engdescr} value={enginelcv.engdescr}>
                {enginelcv.engdescr}
              </option>
            ))}
          </select>
          <select
            value={modelTypeLcvContext.selectedModelTypeLcv}
            onChange={modelTypeLcvContext.handleModelTypeChange}
          >
            <option value=""> Μοντέλο/Τύπος </option>
            {modelTypeLcvContext.modelTypesLcv.map((modelTypeLcv) => (
              <option
                key={modelTypeLcv.vehicle_type_id}
                value={modelTypeLcv.vehicle_type_id}
              >
                {modelTypeLcv.modelAndType}
              </option>
            ))}
          </select>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default SearchLcvEngine;
