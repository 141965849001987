//  src\pages\Root.js

import { Outlet } from "react-router-dom"; //useNavigation

import MainNavigation from "../components/MainNavigation";

function RootLayout() {
  // const navigation = useNavigation();             // new

  return (
    <>
      <MainNavigation />
      <main>
        {/*{navigation.state === 'loading' && <p>Loading...</p>}*/}
        <Outlet />
      </main>
    </>
  );
}

export default RootLayout;