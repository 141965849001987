// // src/components/BackButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "semantic-ui-react";

const BackButton = ({ steps = -1 }) => {
  const navigate = useNavigate();
  return (
    <Button color='blue' icon="arrow left" content="ΠΙΣΩ" onClick={() => navigate(steps)} />
  );
};

  
  export default BackButton;

// src/components/BackButton.js
// import React from 'react';
// // import { useNavigate } from 'react-router-dom';
// import { useNavigation } from "../contexts/history/navigation-history";

// const BackButton = () => {
//   const { popState, getCurrentState } = useNavigation();

//   const handleClick = () => {
//     popState();
//     const previousState = getCurrentState();
//     if (previousState) {
//       // Implement logic to restore the application state from previousState
//       console.log("Navigated to previous state:", previousState);

//       // Here you would typically set your application state based on the previousState.
//       // This could involve setting local state, context state, or any other relevant data.
//       // For demonstration purposes, we are logging the previous state.
//     }
//   };

//   return <button onClick={handleClick}>Go Back</button>;
// };

// export default BackButton;
