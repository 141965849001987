// src/components/show_table/CardBarcode.js

//ShowCard.js
import React from "react";
import { Card, Icon, Image, Message } from "semantic-ui-react";
import { useProductExtraContext } from "../../contexts/additional_info/products-extra-contexts";
import { Oval } from 'react-loader-spinner';


const CardBarcode = () => {
  const productExtraContext = useProductExtraContext();
  // const barcodes = productExtraContext.productsCard;
  const photos = productExtraContext.photos;
  const photosLoading = productExtraContext.photosLoading;

  return (
    <>
    {photosLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
          <Oval color="#00BFFF" height={60} width={60} />
        </div>
      ) : (
        photos && photos.length > 0 ? (
          <Card>
            {photos.map((photo) => (
              <Card.Content key={photo.docname}> {/* Add a key prop to Card.Content */}
                <Card.Header>
                {photo.product_id} - {photo.photosByBrand} <Icon name="industry" />
              </Card.Header>
              <Image
                src={`https://res.cloudinary.com/dov4oyrcf/image/upload/v1699547546/Images/${photo.brand_id}/${photo.docname}.jpg`}
                wrapped
                ui={false}
              />
            </Card.Content>
          ))}

        </Card>

      ) : (

  <Message warning>

    <Message.Header>Δεν υπάρχουν αποτελέσματα</Message.Header>

    <p>Δεν υπάρχουν διαθέσιμες φωτογραφίες για το συγκεκριμένο προΪόν </p>

  </Message>

) )}

</>

);

};



export default CardBarcode;

