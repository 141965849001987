//  src\pages\Root.js

// import { Outlet } from "react-router-dom"; //useNavigation
import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import SidebarSearch from "../components/sidebar/sidebarSearch";
import History from "../components/sidebar/history";
import { ManufContextProvider } from "../contexts/vehicles/manuf-context";
import { ModelContextProvider } from "../contexts/vehicles/model-context";
import { EngineContextProvider } from "../contexts/engines/engine-context";
import { HistoryProvider } from "../contexts/history/history-context";

function Layout({ children }) {

return (
  
  <ManufContextProvider>
    <ModelContextProvider>
      <EngineContextProvider>
          <HistoryProvider>
            <Grid columns={3} >
              <Grid.Row>
                <Grid.Column width={3}>
                  <Segment basic >
                      <SidebarSearch/>
                      <History />
                  </Segment>
                </Grid.Column>
                <Grid.Column width={10} stretched>
                    <Segment basic>
                        {children}
                    </Segment>
                </Grid.Column>
               </Grid.Row>
            </Grid>
          </HistoryProvider>
      </EngineContextProvider>
    </ModelContextProvider>
  </ManufContextProvider>
);
}

export default Layout;
