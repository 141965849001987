//src\components\MainNavigation.js

import React, { useEffect, useState } from "react"; //new
import { Menu, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton"

export default function NavbarLg() {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      setIsAuth(true);
    }
  }, []);

  return (
    <Segment inverted attached size="mini">
      <Menu inverted secondary>
        <Menu.Item name="logo">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="pluto-bg.png"
              alt="Tombaugh"
              style={{ width: "70px", height: "auto" }}
            />
            <h5 style={{ marginTop: "10px" }}>Tombaugh</h5>
          </div>
        </Menu.Item>
          {isAuth ? (
          <>
            <Menu.Item as={Link} to="/" name="ΑΡΧΙΚΗ" position="left" />
            <Menu.Item as={Link} to="/logout" name="ΕΞΟΔΟΣ" position="right" 
          />
          </>
        ) : (
          <Menu.Item as={Link} to="/login" name="ΣΥΝΔΕΣΗ" position="right" />
        )}
         <Menu.Item >
          <BackButton steps={-1} /> {/* You can set steps to `-1` or any number as needed */}
        </Menu.Item>
      </Menu>
    </Segment>
  );
}
