//  src\pages\Home.js

import { useEffect } from "react";
import React from "react"; // new if
import { Link } from "react-router-dom";
import commercial from "../components/commercial.png";
import cars from "../components/cars.png";
import { Grid, Segment } from "semantic-ui-react";

export const HomePage = () => {
  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      window.location.href = "/login";
    } else {
    }
  }, []);

  return (
    <Grid columns="equal">
      <Grid.Column>
        <Segment basic textAlign="center">
          {/* <Link to="sidebar"> */}
          <Link to="passenger">
            <img
              src={cars}
              alt="Cars"
              style={{ width: "200px", height: "auto" }}
            ></img>
            <h2>Επιβατικά Οχήματα</h2>
          </Link>
        </Segment>
      </Grid.Column>

      <Grid.Column>
        <Segment basic textAlign="center">
          <Link to="light_commercial">
            <img
              src={commercial}
              alt="light Commercial"
              style={{ width: "150px", height: "auto" }}
            ></img>
            <h2>Ελαφριά Εμπορικά Οχήματα</h2>
          </Link>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};
