//src\components\show_table\ProductsByVehicle.js

import DataTable from "react-data-table-component";
import {Segment, Grid, Button} from "semantic-ui-react";
import { useProductByLcvContext } from "../../contexts/show_table/prod-table-lcv-context";
import { useGenCategoryContext } from "../../contexts/categories/gen-category-context";
import CardBarcode from "./BarcodeLcvCard";
import ShowOeList from "./ShowOeLcvList";
import React from "react";
import differenceBy from "lodash/differenceBy";


const ShowProductsByLcv = () => {
  const genCategoryContext = useGenCategoryContext();
  const productByLcvContext = useProductByLcvContext();
  const productVehicles = productByLcvContext.productVehicles;
  const columns = productByLcvContext.columns;
  const filter = productByLcvContext.filter;
  const search = productByLcvContext.search;
  const SetSearch = productByLcvContext.SetSearch;

    const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [data, setData] = React.useState(productVehicles);

  const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

  const contextActions = React.useMemo(() => {
		const handleOes = () => {

			if (window.confirm(`Θα πραγματοποιηθεί σύνθετη αναζήτηση ΟΕ για τους κωδικούς:\r ${selectedRows.map(r => r.product_id)}?`)) {
				setToggleCleared(!toggleCleared);
				setData(differenceBy(data, selectedRows, 'title'));
			}
		};

		return (
                <Button
                  basic
                  onClick={handleOes}
                  color='blue'
                  content='ΣΥΝΘΕΤΗ ΑΝΑΖΗΤΗΣΗ'
                  icon='filter'
                  label={{
                    as: 'a',
                    basic: true,
                    color: 'blue',
                    pointing: 'left',
                    content: 'ΟΕ',
                  }}
                />
		);
	}, [data, selectedRows, toggleCleared]);

  const tableHeaderstyle={
      rows: {
        style: {
            maxHeight: '65px', // override the row height
        },
    },
    headCells:{
        style:{
            fontWeight:"bold",
            fontSize:"14px",
            backgroundColor:"#ccc"

        },
    },
   }

  return (
    <Segment basic>
      <Grid stretched={true}>
       <Grid.Column tablet={4} computer={8}>
          {genCategoryContext.selectedGen_category && productVehicles.length > 0 ?
            (
           <DataTable
            title="Προϊόντα"
            customStyles={ tableHeaderstyle }
            columns={ columns }
            data={ filter }
            pagination
            selectableRows
            fixedHeader
            contextActions={ contextActions }
			onSelectedRowsChange={ handleRowSelected }
            clearSelectedRows={ toggleCleared }
            highlightOnHover
            subHeader
             subHeaderComponent={
                <input type="text"
                className="form-control"
                placeholder="Αναζήτηση Μάρκας ... "
                value={ search }
                onChange={(e)=>SetSearch(e.target.value)}
                />
             }
             subHeaderAlign="left"
            />
          )
              : genCategoryContext.selectedGen_category && productVehicles.length === 0 ? (
            <div>
               <p>ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΠΟΤΕΛΕΣΜΑΤΑ</p>
            </div>
         ) : null
          }

        </Grid.Column>

          <Segment basic>
            <Grid.Column tablet={8} computer={4}>
            {productByLcvContext.showCard && <CardBarcode />}

            {productByLcvContext.showOeList && <ShowOeList />}
          </Grid.Column>
          </Segment>
      </Grid>
    </Segment>
  );
};

export default ShowProductsByLcv;
