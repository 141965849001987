//src\contexts\additional_info\product-extra-context.js

import React, { useContext, useState } from "react";

const ProductExtraContext = React.createContext();

export const useProductExtraContext = () =>
  useContext(ProductExtraContext);

export const ProductExtraContextProvider = ({ children }) => {
  const [productsCard, setCardDetails] = useState([]);
  const [showPhotos, setShowPhotos] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [trades, setTrades] = useState([]);
  const [supersedes, setSupersed] = useState([]);
  const [criteriaInfo, setCriteriaInfo] = useState([]);
  const [oes, setOes] = useState([]);
  const [activeComponent, setActiveComponent] = useState(false);
  const [oesLoading, setOesLoading] = useState(false);
  // const [dataLoading, setDataLoading] = useState(false); // New state variable for data loading
  // Individual loading states
  const [cardLoading, setCardLoading] = useState(false);
  const [tradesLoading, setTradesLoading] = useState(false);
  const [supersedesLoading, setSupersedesLoading] = useState(false);
  const [criteriaLoading, setCriteriaLoading] = useState(false);
  const [photosLoading, setPhotosLoading] = useState(false);

  
  const handleTableRowClick = (event, product_id, brand_id) => {
    if (product_id && brand_id > 0) {
      const product_id_new = product_id.replace("/", "");
      setPhotosLoading(true);
      // First API call
      fetch(`https://tombaugh.tech/api/${product_id_new}/images/${brand_id}`)
        .then((res) => res.json())
        .then((data) => {
          setPhotos(data);
          setPhotosLoading(false);
        });
    } else {
      setShowPhotos(false);
    }
    setActiveComponent("CardBarcode");
  };

  const handle2TableRowClick = (event, product_id, brand_id) => {
    if (product_id && brand_id > 0) {
      const product_id_new = product_id.replace("/", "");

      // Start loading states for each request
      setCardLoading(true);
      setTradesLoading(true);
      setSupersedesLoading(true);
      setCriteriaLoading(true);

      // // First API call
      fetch(`https://tombaugh.tech/api/${product_id_new}/barcode/${brand_id}`)
      .then((res) => res.json())
      .then((data) => {
        setCardDetails(data);
        setCardLoading(false); // End loading for card details
      });

       // Second API call
      fetch(`https://tombaugh.tech/api/${product_id_new}/trades/${brand_id}/`)
        .then((res) => res.json())
        .then((data) => {
          setTrades(data);
          setTradesLoading(false); // End loading for trades
        });

      // Third API call
      fetch(`https://tombaugh.tech/api/${product_id_new}/supersed/${brand_id}/`)
        .then((res) => res.json())
        .then((data) => {
          setSupersed(data);
          setSupersedesLoading(false); // End loading for supersedes
        });

     // Fourth API call
      fetch(`https://tombaugh.tech/api/${product_id_new}/criteria/${brand_id}/`)
        .then((res) => res.json())
        .then((data) => {
          setCriteriaInfo(data);
          setCriteriaLoading(false); // End loading for criteria info
        })
        .catch((error) => console.error("Error fetching criteria info:", error))
        .finally(() => {
          setCriteriaLoading(false); // Handle error case
        });
      setActiveComponent("ProductInfo");
    }
};

  const handle3TableRowClick = (event, product_id, brand_id) => {

    if (product_id && brand_id > 0) {
        const product_id_new = product_id.replace("/", "");
        setOesLoading(true); // Start the loading
        fetch(`https://tombaugh.tech/api/${product_id_new}/${brand_id}/oe/`)
            .then((res) => res.json())
            .then((data) => {
                setOes(data);
                setOesLoading(false); // End the loading
            })
            .catch(() => {
              setOesLoading(false); // End the loading in case of an error
          });
      }
      setActiveComponent("OeList4One");
      };

  return (
    <ProductExtraContext.Provider
      value={{
        productsCard,
        photos,
        showPhotos,
        trades,
        supersedes,
        criteriaInfo,
        oes,        
        activeComponent,
        setActiveComponent,
        handleTableRowClick,
        handle2TableRowClick,
        handle3TableRowClick,
        oesLoading,
        cardLoading,
        tradesLoading,
        supersedesLoading,
        criteriaLoading,
        photosLoading
      }}
    >
      {children}
    </ProductExtraContext.Provider>
  );
};

export default ProductExtraContextProvider;