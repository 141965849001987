//src\contexts\products\category-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

const CategoryContext = React.createContext();

export const useCategoryContext = () => useContext(CategoryContext);

export const CategoryContextProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    axios.get("https://tombaugh.tech/api/categories/").then(({ data }) => {
      const distinctCategories = [
        ...new Set(data.map(({ assembly_group }) => assembly_group)),
      ];
      setCategories(distinctCategories);
    });
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <CategoryContext.Provider
      value={{
        categories,
        selectedCategory,
        handleCategoryChange,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
