// src/components/categories_tree/ShortCategories.js
import React, { useState } from "react";
import { Accordion, Icon, Segment } from "semantic-ui-react";
import { useCatNodes1Context } from "../../contexts/categories_tree/cat-node1";
import { useCatNodes2Context } from "../../contexts/categories_tree/cat-node2";
import { useCatNodes3Context } from "../../contexts/categories_tree/cat-node3";
import { useCatNodes4Context } from "../../contexts/categories_tree/cat-node4";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CategoryIcons from "./CategoryIcons";  // Import the new component
import CategoryFavorites from "./CategoryFavorites"; 
import "../../App.css";


const ShortCategories = ({ onCategoryClick, showAccordion }) => {

  const catNodes1Context = useCatNodes1Context();
  const catNodes2Context = useCatNodes2Context();
  const catNodes3Context = useCatNodes3Context();
  const catNodes4Context = useCatNodes4Context();

  const productsNavigation = useNavigate();

  const [activeIndex, setActiveIndex] = useState([null, null, null, null]);

  const location = useLocation();
  const selectedVehicle = (location.state && location.state.selectedVehicle) || "";


  const handleClick = (index, selectedValue, level) => {
    if (!selectedValue) {
      console.error("selectedValue is null");
      return;
    }

  onCategoryClick(); // Notify parent component that a category has been clicked
  const newActiveIndex = [...activeIndex];
  newActiveIndex[level] = activeIndex[level] === index ? null : index;

  setActiveIndex(newActiveIndex);

  switch (level) {
    case 0:
      catNodes1Context.handleCatNodes1Change(selectedValue);
      break;
    case 1:
      if (catNodes2Context.catNodes2[index].flag_api === 1) {
        productsNavigation("/products", { state: { selectedValue, selectedVehicle } });
      } else {
        catNodes2Context.handleCatNodes2Change(selectedValue);
      }
      break;
    case 2:
      if (catNodes3Context.catNodes3[index].flag_api === 1) {
        productsNavigation("/products", { state: { selectedValue, selectedVehicle } });
       } else {
        catNodes3Context.handleCatNodes3Change(selectedValue);
      }
      break;
    case 3:
      catNodes4Context.handleCatNodes4Change(selectedValue);
      productsNavigation("/products", { state: { selectedValue, selectedVehicle } });
      break;
    default:
      break;
  }
};


return (
  <>
        <Segment basic textAlign="center" >
          <h3>Κορυφαίες  Κατηγορίες</h3>
              {/* <CategoryIcons
                  categories={catNodes1Context.filteredCatNodes1}
                  onCategoryClick={handleClick}
              /> */}
              <CategoryFavorites
              onCategoryClick={handleClick}
              />
          </Segment>
          <Segment basic  >
            {showAccordion && (
              <Accordion styled style={{ overflow: 'auto', maxHeight: '600px', fontSize: '12px' }}>
              {catNodes2Context.catNodes2.map((catNode2, index2) => (
              <div key={catNode2.node_id2} value={catNode2.node_id2}>
                <Accordion.Title
                  active={activeIndex[1] === index2}
                  index={index2}
                  onClick={() => handleClick(index2, catNode2.node_id2, 1)}
                >
                 <Icon name={activeIndex[1] === index2 ? 'angle down' : 'angle right'} />
                 <span className={activeIndex[1] === index2 ? 'active-text' : 'inactive-text'}>
                 {catNode2.str_text2} </span>                 
                </Accordion.Title>
                <Accordion.Content active={activeIndex[1] === index2}>
                  {catNodes3Context.catNodes3.map((catNode3, index3) => (
                    <div key={catNode3.node_id3} value={catNode3.node_id3}>
                      <Accordion.Title
                        active={activeIndex[2] === index3}
                        index={index3}
                        onClick={() =>
                          handleClick(index3, catNode3.node_id3, 2)
                        }
                      >
                         <Icon name={activeIndex[2] === index3 ? 'angle down' : 'angle right'} />
                         <span className={activeIndex[2] === index3 ? 'active-text' : 'inactive-text'}>
                         {catNode3.str_text3} </span>     
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex[2] === index3}>
                        {catNodes4Context.catNodes4.map((catNode4, index4) => (
                          <div
                            key={catNode4.node_id4}
                            value={catNode4.node_id4}
                          >
                            <Accordion.Title
                              active={activeIndex[3] === index4}
                              index={index4}
                              onClick={() =>
                                handleClick(index4, catNode4.node_id4, 3)
                              }
                            >
                              <Icon name={activeIndex[3] === index4 ? 'angle down' : 'angle right'} />
                              <span className={activeIndex[3] === index4 ? 'active-text' : 'inactive-text'}>
                              {catNode4.str_text4} </span>     
                            </Accordion.Title>
                          </div>
                        ))}
                    </Accordion.Content>
                    </div>
                    ))}
              </Accordion.Content>
              </div>
              ))}
          </Accordion>
          )}
          </Segment>
        </>
      );
};    


export default ShortCategories;