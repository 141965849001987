//  src\components\sidebar\history.js

import React from "react";
import { Header, Grid, Segment } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import classes from "./History.module.css";

import { useNavigate } from "react-router-dom"; //  new
import { useHistoryContext } from "../../contexts/history/history-context";

const History = () => {
  const { history } = useHistoryContext();
  const historyNavigation = useNavigate();


  const handleSelectedHistoryItem = (selectedItem) => {

    historyNavigation("/categories", {
  
      state: { 
  
        selectedVehicle: {
  
          vehicle_type_id: selectedItem.vehicle_type_id,
  
          manufacturer: selectedItem.manufacturer,
  
          model: selectedItem.model,
  
          type: selectedItem.type
  
        }
  
      },
  
    });
  
  };

  return (
    <React.Fragment>
      <Segment basic>
        <Grid columns={1}>
          <Grid.Column stretched={true}>
            <Header as="h4" textAlign={"center"}>
              Ιστορικό επιλεγμένων αυτ/των
            </Header>
            {/* {history ( */}
            <div className={classes.highlight}>
              {history.map((item, index) => (
                <div
                  className={classes.pointer}
                  key={index}
                  onClick={() => handleSelectedHistoryItem(item)}
                >
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className={classes.icon}
                      />
                      {item.manufacturer} {item.model}
                      {item.type}
                    </li>
                    {/* Add more list items for other properties */}
                  </ul>
                </div>
              ))}
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
    </React.Fragment>
  );
};

export default History;
