//src\components\products\Products.js

import React from "react";
import { Segment, Header, Grid } from "semantic-ui-react";
import { useCategoryContext } from "../../contexts/categories/category-context";
import { useSubCategoryContext } from "../../contexts/categories/sub-category-context";
import { useGenCategoryContext } from "../../contexts/categories/gen-category-context";

const SearchCategory = () => {
  const categoryContext = useCategoryContext();
  const subCategoryContext = useSubCategoryContext();
  const genCategoryContext = useGenCategoryContext(); //new

  return (
    <Segment basic>
      <Grid textAlign={"center"} columns={1}>
        <Grid.Column stretched={true}>
          <Header as="h4">Κατηγορίες Προϊόντων</Header>
          <select
            value={categoryContext.selectedCategory}
            onChange={categoryContext.handleCategoryChange}
          >
            <option value=""> Κατηγορία </option>
            {categoryContext.categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>

          {/*<Header as="h3">Search Sub Category</Header>*/}
          <select
            value={subCategoryContext.selectedSub_category}
            onChange={subCategoryContext.handleSubCategoriesChange}
          >
            <option value=""> Υποκατηγορία </option>
            {subCategoryContext.sub_categories.map((sub_category) => (
              <option key={sub_category} value={sub_category}>
                {sub_category}
              </option>
            ))}
          </select>

          {/*<Header as="h3">Search Products</Header>*/}
          <select
            value={categoryContext.selectedGen_category}
            onChange={genCategoryContext.handleGenCategoriesChange} //correct IT
          >
            <option value=""> Προϊόν </option>
            {genCategoryContext.gen_categories.map((gencategory) => (
              <option
                key={gencategory.prod_gen_id}
                value={gencategory.prod_gen_id}
              >
                {gencategory.descr}
              </option>
            ))}
          </select>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default SearchCategory;
