//model-context.js
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useTypeLcvContext } from "../vehicles/type-lcv-context";

const ExtraCarsLcvContext = React.createContext();

export const useExtraCarsLcvContext = () => useContext(ExtraCarsLcvContext);

export const ExtraCarsLcvContextProvider = ({ children }) => {
  const [carsInfo, setCarsInfo] = useState([]);
  const TypeLcvContext = useTypeLcvContext();

  useEffect(() => {
    if (TypeLcvContext.selectedType) {
      axios
        .get(
          `https://tombaugh.tech/api/${TypeLcvContext.selectedType}/extra-info`
        )
        .then((res) => {
          setCarsInfo(res.data);
        });
    } else {
      setCarsInfo([]);
    }
  }, [TypeLcvContext.selectedType]);

  return (
    <ExtraCarsLcvContext.Provider
      value={{
        carsInfo,
        setCarsInfo,
      }}
    >
      {children}
    </ExtraCarsLcvContext.Provider>
  );
};
