// src/components/show_table/CardBarcode.js

//ShowCard.js
import React from "react";
import {Card, Icon, Image} from "semantic-ui-react";
import { useProductByEngineContext } from "../../contexts/show_table/prod-table-eng-context";

const CardEngineBarcode = () => {
  const productByEngineContext = useProductByEngineContext();
  const products = productByEngineContext.products;
  const photos= productByEngineContext.photos;

  return (
    <>
      {products && products.length > 0
        ? (products.map((product) => (
            // (photos.map((photo) => (
            <Card key={product.product_id}>
           {photos && photos.length > 0 ? (
             photos.map(photo => (
              <Image
                src={`https://res.cloudinary.com/dov4oyrcf/image/upload/v1699547546/Images/${photo.brand_id}/${photo.docname}.jpg`} wrapped ui={false}
              />
            ))) : null}
              <Card.Content>
                <Card.Header>
                  {product.product_id} - {product.barcodesByBrand}{" "}
                  <Icon name="industry" />
                </Card.Header>
                <Card.Meta>
                  <Icon name="barcode" />
                  <span className="date">Barcode : </span>
                </Card.Meta>
                <Card.Description>{product.ean_id}</Card.Description>
              </Card.Content>
            </Card>
          )))
         // )))
        : null}

    </>
  );
};

export default CardEngineBarcode;
