//model-context.js
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useManufLcvContext } from "../vehicles/manuf-lcv-context";

const EngineLcvContext = React.createContext();

export const useEngineLcvContext = () => useContext(EngineLcvContext);

export const EngineLcvContextProvider = ({ children }) => {
  const [lcvEngines, setLcvEngines] = useState([]);
  const [selectedLcvEngine, setSelectedLcvEngine] = useState(null); //selectedModel
  const manufContext = useManufLcvContext();

  useEffect(() => {
    if (manufContext.selectedManufacturer) {
      axios
        .get(
          `https://tombaugh.tech/api/manufacturers-lcv/${manufContext.selectedManufacturer}/engines`
        )
        .then((res) => {
          setLcvEngines(res.data);
        });
    } else {
      setLcvEngines([]);
    }
  }, [manufContext.selectedManufacturer]);

  const handleLcvEngineChange = (event) => {
    setSelectedLcvEngine(event.target.value);
  };

  return (
    <EngineLcvContext.Provider
      value={{
        lcvEngines,
        setLcvEngines,
        selectedLcvEngine,
        handleLcvEngineChange,
      }}
    >
      {children}
    </EngineLcvContext.Provider>
  );
};
