import React, { useContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useProductsContext } from "../../contexts/products/products";
import { useProductExtraContext } from "../../contexts/additional_info/products-extra-contexts";


const BrandsOfProductsContext =  React.createContext();

export const useBrandsOfProductsContext = () => useContext(BrandsOfProductsContext);


export const BrandsOfProductsContextProvider = ({ children }) => {

    const productsContext = useProductsContext();
    const products = productsContext.products;
    const [brandsbyProd, setBrandsbyProd] = useState([]);
    const [loading, setLoading] = useState(true);
    const [brandImages, setBrandImages] = useState({});
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [showProductsComponent, setShowProductsComponent] = useState(false); // New state to manage ShowProducts component visibility
    const {
      setActiveComponent,
      activeComponent
  } = useProductExtraContext();


  useEffect(() => {
    if (products && products.length > 0) {
      const uniqueBrands = products.filter((item, index, self) => 
        index === self.findIndex((t) => t.brand_id === item.brand_id)
      );
      setBrandsbyProd(uniqueBrands);

      // Fetch images for the unique brands
      const fetchImages = async () => {
        setLoading(true);
        const images = {};
        const fetchImage = async (brandId, ext) => {
          try {
            const url = `https://res.cloudinary.com/dov4oyrcf/image/upload/v1699547546/Images/Logos1/${brandId}.${ext}`;
            const response = await axios.get(url);
            if (response.status === 200) {
              return url;
            }
          } catch (error) {
            console.error(`Error fetching ${ext} image for brand ${brandId}:`, error);
          }
          return null;
        };
		
		      for (const brand of uniqueBrands) {
          let imageUrl = await fetchImage(brand.brand_id, 'jpg');
          if (!imageUrl) {
            imageUrl = await fetchImage(brand.brand_id, 'bmp');
          }
          if (imageUrl) {
            images[brand.brand_id] = imageUrl;
          }
        }
        setBrandImages(images);
        setLoading(false);
      };
	  
	     fetchImages();
    } else {
      setLoading(false); // Set loading state to false if no products found
    }
  }, [products]);

  // Sort brands in alphabetical order
  const sortedBrandsByProd = useMemo(() => {
    return [...brandsbyProd].sort((a, b) => a.brands.localeCompare(b.brands));
  }, [brandsbyProd]);

  // Filter products by brand_id
  const handleFilterByBrand = (brand_id) => {
    console.log('Filtering by brand_id:', brand_id); // Debugging line
    const filtered = products.filter(product => product.brand_id === brand_id);
    console.log('Filtered products:', filtered); // Debugging line
    setFilteredProducts(filtered);
    setShowProductsComponent(false); // Appropriate visibility state
    setActiveComponent(!activeComponent);
  };


  return (
    <BrandsOfProductsContext.Provider value={{ 
        brandImages,
        filteredProducts,
        showProductsComponent,
        setShowProductsComponent,
        handleFilterByBrand,
        brandsbyProd: sortedBrandsByProd,
        loading
       }}>
      {children}
    </BrandsOfProductsContext.Provider>
  );
};

