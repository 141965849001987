//src\contexts\products\subcategory-context.js

import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useCatNodes3Context } from "./cat-node3";

const CatNodes4Context = React.createContext();

export const useCatNodes4Context = () => useContext(CatNodes4Context);

export const CatNodes4ContextProvider = ({ children }) => {
    const [catNodes4, setCatNodes4] = useState([]);
    const [selectedCatNodes4, setSelectedCatNodes4] = useState(null);
  const catNodes3Context = useCatNodes3Context();


  useEffect(() => {
    if (catNodes3Context.selectedCatNodes3) {
      axios
        .get(
          `https://tombaugh.tech/api/${catNodes3Context.selectedCatNodes3}/nodes4/`
        )
        .then((res) => {
          setCatNodes4(res.data);
        });
    } else {
        setCatNodes4([]);
    }
  }, [catNodes3Context.selectedCatNodes3]);



  const handleCatNodes4Change = (selectedValue) => {
    setSelectedCatNodes4(selectedValue);
  };

  return (
    <CatNodes4Context.Provider
      value={{
        catNodes4,
        setCatNodes4,
        selectedCatNodes4,
        handleCatNodes4Change,
      }}
    >
      {children}
    </CatNodes4Context.Provider>
  );
};